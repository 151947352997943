import React, {useEffect, useState} from "react";
import useAlertService from "../../api/services/useAlertService";
import useQueryString from "../../hooks/useQueryString";
import Loading from "../../components/Loading/Loading";
import useData from "../../hooks/useData";
import Button from "../../components/Button/Button";
import swal from "sweetalert";
import spotGroupIcon from "../../assets/icons/spot-group.svg";
import standIcon from "../../assets/icons/stand.svg";
import "./style.scss"

const UserAlerts = () => {

  const {userShowCollection, userDelete} = useAlertService()
  const [alerts, setAlerts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {getIndustryById, getTypeById} = useData()
  const {getUrl} = useQueryString()

  useEffect(() => {
    userShowCollection(
      (alerts) => {
        setAlerts(alerts)
        setIsLoading(false)
      },
      (errors) => {

      },
    )
  }, [])


  const handleDelete = (id) => {
    swal({
      title: 'Deze Alert Verwijderen?',
      icon: 'warning',
      dangerMode: true,
      text: '',
      buttons: {
        cancel: {
          text: 'Annuleren',
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: 'Verwijderen',
          value: true,
          visible: true,
          className: 'btn-color-red btn-size-m',
          closeModel: true,
        }
      }
    }).then((result) => {
      if (result) {
        setAlerts([])
        setIsLoading(true)
        userDelete(
          (alerts) => {
            setAlerts(alerts)
            setIsLoading(false)
          },
          (errors) => {

          },
          id
        )
      }
    })
  }

  return (
    <div className="page-user-alerts">
      <h1>Zoek Alert Meldingen</h1>
      <p>Standplaats.nl speurt elk uur naar nieuwe locaties, standplaatsen of stands en trucks die aansluiten bij de
        Alerts die je hieronder hebt ingesteld.</p>
      <p>Wil je een nieuwe alert instellen? Bezoek Standplaats.nl, pas de zoekfilters aan de linkerzijde aan naar jouw
        voorkeur, en klik onder de zoekresultaten op 'Alert meldingen ontvangen' om geïnformeerd te blijven.</p>
      {isLoading ?
        <Loading/>
        :
        <div className="alerts">
          {alerts.length === 0 ?
            <p>Nog geen alerts ingesteld</p>
            :
            <>
              <div className="table-header">
                <div className="table-col what">
                  Zoekt
                </div>
                <div className="table-col where">
                  Waar
                </div>
                <div className="table-col types">
                  Types
                </div>
                <div className="table-col industries">
                  Branches
                </div>
                <div className="table-col results">
                  Resultaten
                </div>
                <div className="table-col button">
                  <p></p>
                </div>
              </div>

              {alerts.map((alert, i) => {
                return (
                  <div key={i} className="table-row">
                    <div className="table-col what">
                      <span className="inline-header">Zoekt: </span>
                      {(alert.filter.search_model === 'stand')
                        ? <img width={23} src={standIcon} alt="Stand of truck"/>
                        : <img width={23} src={spotGroupIcon} alt="Locatie / standplaats"/>
                      }<br/>
                      {(alert.filter.search_model === 'stand') ? 'Stand' : 'Standplaats'}
                    </div>
                    <div className="table-col where">
                      <span className="inline-header">Waar: </span>
                      {(!alert.filter.where.city && !alert.filter.where.postal_code)
                        ? 'Heel Nederland'
                        :
                        <>
                          {(alert.filter.where.city) ? 'Stad: ' + alert.filter.where.city + ' ' : ''}
                          {(alert.filter.where.postal_code) ? 'Postcode: ' + alert.filter.where.postal_code + ' ' : ''}
                          {(alert.filter.where.radius) ? 'Afstand: ' + alert.filter.where.radius + ' km' : ''}
                        </>
                      }
                    </div>
                    <div className="table-col types">
                      <span className="inline-header">Types: </span>
                      {!alert.filter.types || (alert.filter.types && alert.filter.types.length === 0)
                        ? <span>Alle types</span>
                        : alert.filter.types.map((typeId, i) => {
                          return (
                            <span key={i}>{getTypeById(typeId).label}, </span>
                          )
                        })}
                    </div>
                    <div className="table-col industries">
                      <span className="inline-header">Branches: </span>
                      {!alert.filter.industries || (alert.filter.industries && alert.filter.industries.length === 0 )
                        ? <span>Alle branches</span>
                        : alert.filter.industries.map((industryId, i) => {
                          return (
                            <span key={i}>{getIndustryById(industryId).label}, </span>
                          )
                        })}
                    </div>
                    <div className="table-col results">
                      <span className="inline-header">Resultaten</span>
                      {alert.prev_results.length}<br/>
                      {/*{alert.count_results}<br/>*/}
                      {/*{(alert.prev_results && alert.prev_results.length !== alert.count_results) ? 'Nieuw: ' + (alert.prev_results.length - alert.count_results) : ''}*/}
                    </div>
                    <div className="table-col buttons">
                      <div className="container">
                        <Button
                          label="Resultaten"
                          link={'/?'+getUrl(alert.filter)}
                          size="s"
                          // disabled={!alert.count_results}
                          color="green"
                        />
                        <Button
                          label="Verwijderen"
                          onClick={() => handleDelete(alert.id)}
                          color="red"
                          size="s"
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          }
        </div>
      }
    </div>
  )
}

export default UserAlerts
