import {useState} from "react";
import useUserService from "../api/services/useUserService";
import useFunction from "../hooks/useFunction";
import swal from "sweetalert";
import * as Sentry from "@sentry/react";


const Auth = () => {

  const [user, setUser] = useState({});
  const {c, getToken, objIsEmpty} = useFunction()
  const {userShow} = useUserService()

  if (getToken()) {
    if (objIsEmpty(user)) {
      userShow(
        (data) => {
          //success
          c('User loaded: ', data)

          if (data) {
            setUser(prevAuth => {
              return {
                ...prevAuth,
                ...data
              }
            })

            Sentry.setUser({
              id: data.id,
              email: data.email,
              ...(data.active_company && {
                active_company_id: data.active_company.id,
                active_company_legal_name: data.active_company.legal_name,
              }),
            });

          } else {
            swal('Error', 'Get userShow Error, empty response', 'warning')
          }
        })
    }
  }
  return {
    user,
    setUser
  };
}

export default Auth
