import React from "react";
import PropTypes from "prop-types";
import './style.scss'

const Loading = (props) => {

  const {loadingIconFixed = true} = props

  return (
    <div
      className="component-loading"
      style={('height' in props)?{height: props.height}:{}}
    >
      <div className={(loadingIconFixed)?"lds-ripple-container is-fixed":"lds-ripple-container"}>
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

Loading.propTypes = {
  loadingIconFixed: PropTypes.bool,
  height: PropTypes.number,
}
export default Loading
