import {useEffect, useState} from "react";
import useSpotGroupSpotsService from "../api/services/useSpotGroupSpotsService";
import useFunction from "./useFunction";
import cloneDeep from "lodash/cloneDeep"

const useSpotMapForm = (props) => {
  const {
    id,
    onChange,
    onReset,
    wizard,
    updateSuccess,
    form,
  } = props

  const [spotGroup, setSpotGroup] = useState(null)
  const [isChanged, setIsChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedMappable, setSelectedMappable] = useState(null)
  const spotGroupSpotsService = useSpotGroupSpotsService()
  const {c} = useFunction()

  /**
   *
   */
  useEffect(() => {
    if (isChanged && onChange) {
      onChange()
    }
    if (spotGroup) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
      spotGroupSpotsService.userSpotMapShow(
        (spotGroup) => {
          saveSpotGroup(spotGroup)
        },
        (errors) => {
          c('Validation errors', errors)
        },
        id
      )
    }
  }, [])

  useEffect(() => {
    if (isChanged) {
      if (wizard && wizard.setButtonNext && wizard.buttonNext) {
        wizard.setButtonNext({
          ...wizard.buttonNext, [wizard.step]: {
            onClick: () => handleSave(),
            label: 'Opslaan',
            disabled: false,
            visible: true,
          }
        })
      }

      if (wizard && wizard.buttonPrev && wizard.setButtonPrev) {
        wizard.setButtonPrev({
          ...wizard.buttonPrev, [wizard.step]: {
            onClick: () => handleCancel(),
            label: 'Reset',
            disabled: false,
            visible: true,
          }
        })
      }
    }
  }, [isChanged])

  /**
   *
   */
  const changeRequestSpotMapCreation = (e) => {
    //hacky way but seems to work better than normal way
    spotGroup.requests_spot_map_creation = !spotGroup.requests_spot_map_creation
    saveSpotGroup(spotGroup)
    setIsChanged(true)
  }

  /**
   *
   *
   * @param mappable
   */
  const addSelected = (mappable) => {
    setSelectedMappable(mappable)
  }

  /**
   *
   * @param mappable
   * @returns {boolean}
   */
  const isSelected = (mappable) => {
    return (
      selectedMappable &&
      selectedMappable.id === mappable.id &&
      selectedMappable.object_type === mappable.object_type
    )
  }

  /**
   *
   * @param mappable
   */
  const toggleSelected = (mappable) => {
    if (isSelected(mappable)) {
      removeSelected()
    } else {
      addSelected(mappable)
    }
  }

  /**
   *
   */
  const removeSelected = () => {
    setSelectedMappable(false)
  }

  /**
   * Get spot object by id from spotGroup
   *
   * @param spotId
   * @returns {*}
   */
  const getSpotById = (spotId) => {
    if (spotGroup) {
      return spotGroup.spots.find(spot => spot.id === spotId)
    }
  }

  /**
   * Make it snap then, on snapSize
   *
   * @param number
   * @return {number}
   */
  const round = (number) => {
    const snapSize = 0.1;
    return Number((Math.round(number / snapSize) * snapSize).toFixed(1));
  }

  /**
   * Handle Spot resize
   *
   * @param mappable
   * @param style
   * @param map
   */
  const handleResize = (mappable, style, map) => {
    const {top, left, width, height} = style
    const scaleFactor = map.scaleFactor
    // const zoomLevel = 1 //todo somehow this causes a bug
    const zoomLevel = map.zoomLevel

    // Adjust dimensions based on the zoom level
    // We use (zoomLevel * zoomLevel) to account for the scaling effect of the zoom
    mappable.top = round(parseFloat(top) / scaleFactor / zoomLevel);
    mappable.left = round(parseFloat(left) / scaleFactor / zoomLevel);
    mappable.width = round(parseFloat(width) / scaleFactor / zoomLevel);
    mappable.length = round(parseFloat(height) / scaleFactor / zoomLevel);

    setSpotGroup({...spotGroup, spotGroup})
    setIsChanged(true);
  };

  /**
   * Handle spot rotation
   *
   * @param mappable
   * @param rotate
   * @param map
   */
  const handleRotate = (mappable, rotate, map) => {
    mappable.rotate = round(rotate)
    setSpotGroup({...spotGroup, spotGroup})
    setIsChanged(true)
  }

  /**
   * Handle spot drag
   *
   * @param mappable
   * @param deltaX
   * @param deltaY
   * @param map
   */
  const handleDrag = (mappable, deltaX, deltaY, map) => {
    deltaX = deltaX / map.scaleFactor / map.zoomLevel
    deltaY = deltaY / map.scaleFactor / map.zoomLevel

    mappable.top = parseFloat(mappable.top) + deltaY
    mappable.left = parseFloat(mappable.left) + deltaX

    mappable.height = parseFloat(mappable.height)
    mappable.width = parseFloat(mappable.width)

    collisionDetection(mappable, map) //todo somehow this is not working (height an top are NaN of mappeble)

    setSpotGroup({...spotGroup, spotGroup})
    setIsChanged(true)
  }

  /**
   *
   */
  const handleCancel = () => {
    if (onReset) {
      onReset()
    }
    setSpotGroup(cloneDeep(form.originalFormDataModel))
    setIsChanged(false)
  }

  /**
   *
   * @param spotGroup
   */
  const saveSpotGroup = (spotGroup) => {
    setSpotGroup(postService(spotGroup))
    form.setOriginalFormDataModel(cloneDeep(postService(spotGroup)))
    form.setFormData(prevFormData => {
      return {
        ...prevFormData,
        model: postService(spotGroup),
        isChanged: false,
        isStored: true,
        isLoading: false,
      }
    })
    setIsChanged(false)
    setIsLoading(false)
  }

  /**
   * set index in index
   *
   * @param spotGroup
   * @returns {*}
   */
  const postService = (spotGroup) => {
    spotGroup.spots.length && spotGroup.spots.map((spot, i) => {
      spot.index = i
      return spot
    })

    spotGroup.spot_clusters.length && spotGroup.spot_clusters.map((spotCluster, i) => {
      spotCluster.index = i
      return spotCluster
    })

    return spotGroup
  }

  /**
   *
   */
  const handleSave = () => {
    setIsLoading(true)
    setSelectedMappable(null)
    spotGroupSpotsService.userSpotMapUpdate(
      (newSpotGroup) => {
        saveSpotGroup(newSpotGroup)
        if (updateSuccess) {
          updateSuccess()
        }
      },
      (errors) => {
        c('Validation errors', errors)
      },
      id,
      {
        spot_group: {
          id: spotGroup.id,
          requests_spot_map_creation: spotGroup.requests_spot_map_creation,
        },
        spots: spotGroup.spots,
        spot_clusters: spotGroup.spot_clusters,
        spot_maps: spotGroup.spot_maps,
      }
    )
  }

  /**
   *
   * @param e
   * @param map
   */
  const onchangeInput = (e, map) => {
    selectedMappable[e.target.name] = e.target.value
    collisionDetection(selectedMappable, map)
    setSpotGroup({...spotGroup, spotGroup})
    setIsChanged(true)
  }

  /**
   *
   * @param e
   */
  const onchangeInputSpotGroup = (e) => {
    setSpotGroup({...spotGroup, [e.target.name]: e.target.value})
    setIsChanged(true)
  }

  /**
   * Keep the Spots inside the container
   *
   * @param mappable
   * @param map
   * @return {*}
   */
  const collisionDetection = (mappable, map) => {
    // Ensure mappable dimensions do not exceed map dimensions
    mappable.width = Math.min(mappable.width, map.getAreaWidth());
    mappable.length = Math.min(mappable.length, map.getAreaLength());

    // Allow mappable to be 50% outside on each side
    const halfWidth = mappable.width / 2;
    const halfHeight = mappable.length / 2;

    // Adjust mappable position to stay within the left boundary
    mappable.left = Math.max(mappable.left, -halfWidth);
    // Adjust mappable position to stay within the right boundary
    mappable.left = Math.min(mappable.left, map.getAreaWidth() - halfWidth);

    // Adjust mappable position to stay within the top boundary
    mappable.top = Math.max(mappable.top, -halfHeight);
    // Adjust mappable position to stay within the bottom boundary
    mappable.top = Math.min(mappable.top, map.getAreaLength() - halfHeight);

    return mappable;
  }

  /**
   *
   * @param map
   */
  const setMappablesInsideArea = (map) => {
    spotGroup.spots.map((spot) => {
      return collisionDetection(spot, map)
    })
    spotGroup.spot_clusters.map((spot) => {
      return collisionDetection(spot, map)
    })
    setSpotGroup({...spotGroup, spotGroup})
    setIsChanged(true)
  }

  return {
    spotGroup,
    setSpotGroup,
    handleDrag,
    handleResize,
    handleRotate,
    addSelected,
    toggleSelected,
    removeSelected,
    isSelected,
    getSpotById,
    selectedMappable,
    setSelectedMappable,
    isChanged,
    setIsChanged,
    onchangeInputSpotGroup,
    isLoading,
    setIsLoading,
    handleCancel,
    handleSave,
    onchangeInput,
    form,
    saveSpotGroup,
    wizard,
    setMappablesInsideArea,
    collisionDetection,
    changeRequestSpotMapCreation,
  }
}


export default useSpotMapForm
