import useFunction from "../../hooks/useFunction";
import useApi from "../../api/useApi";

const useUserSettingsService = () => {

  const {c} = useFunction()
  const {api} = useApi()

  const userShow = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user/settings',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in show service', error),
    )
  }

  const userUpdate = (callbackSuccess, callbackValidationErrors, data) => {
    return api(
      'post',
      'user/settings/update',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in update service', error),
      data,
    )
  }

  return {
    objectType:'user',
    userShow,
    userUpdate,
  }
}
export default useUserSettingsService