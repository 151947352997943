import swal from "sweetalert";
import ReactDOM from "react-dom/client"; // Importeer createRoot uit react-dom/client
import React from "react";

const useSwal = () => {

  const popup = (props) => {
    const {
      title,
      text,
      Component, // Optional React-component
      reason,
      setReason,
      buttonConfirmLabel,
      buttonCancelLabel,
      callbackConfirm,
      callbackCancel,
      dangerMode,
      buttonConfirmClassname,
      buttonCancelClassname,
      hideButtonCancel,
      hideButtonConfirm,
    } = props;

    // Maak een tijdelijke div om je tekst en React-component in te renderen
    const wrapper = document.createElement("div");

    swal({
      title: title,
      content: wrapper, // Render tekst en component in deze wrapper
      dangerMode: dangerMode ? dangerMode : true,
      buttons: {
        cancel: !hideButtonCancel
          ? {
            text: buttonCancelLabel ? buttonCancelLabel : "Annuleren",
            value: null,
            visible: true,
            className: buttonConfirmClassname
              ? buttonConfirmClassname
              : "btn-color-grey btn-size-m",
            closeModal: true,
          }
          : undefined,
        confirm: !hideButtonConfirm
          ? {
            text: buttonConfirmLabel ? buttonConfirmLabel : "Ok",
            value: true,
            visible: true,
            className: buttonCancelClassname
              ? buttonCancelClassname
              : "btn-color-green btn-size-m",
            closeModal: true,
          }
          : undefined,
      },
    }).then((result) => {
      if (result) {
        callbackConfirm && callbackConfirm();
      } else {
        callbackCancel && callbackCancel();
      }
    });

    // Render tekst en component in de wrapper
    if (text) {
      const textElement = document.createElement("p");
      textElement.textContent = text;
      wrapper.appendChild(textElement); // Voeg de tekst eerst toe
    }

    if (Component) {
      const componentElement = document.createElement("div");
      wrapper.appendChild(componentElement); // Plaats de component onder de tekst

      // Gebruik createRoot om de React-component te renderen
      const root = ReactDOM.createRoot(componentElement);
      root.render(<Component/>);
    }
  };

  return {
    popup,
  };
};

export default useSwal;


// import swal from "sweetalert";
//
//
// const useSwal = () => {
//
//
//   const popup = (props) => {
//
//     const {
//       title,
//       text,
//       buttonConfirmLabel,
//       buttonCancelLabel,
//       callbackConfirm,
//       callbackCancel,
//       dangerMode,
//       buttonConfirmClassname,
//       buttonCancelClassname,
//       hideButtonCancel,
//       hideButtonConfirm,
//     } = props
//
//     swal({
//       title: title,
//       text: text,
//       icon: '', //question
//       dangerMode: dangerMode?dangerMode:true,
//       buttons: {
//         cancel: (!hideButtonCancel)?{
//           text: buttonCancelLabel ? buttonCancelLabel : "Annuleren",
//           value: null,
//           visible: true,
//           className: (buttonConfirmClassname)?buttonConfirmClassname:'btn-color-grey btn-size-m',
//           closeModal: true,
//         }: undefined,
//         confirm: (!hideButtonConfirm)?{
//           text: buttonConfirmLabel ? buttonConfirmLabel : "Ok",
//           value: true,
//           visible: true,
//           className: (buttonCancelClassname)?buttonCancelClassname:'btn-color-green btn-size-m',
//           closeModal: true,
//         }: undefined
//       }
//     }).then((result) => {
//
//
//       if (result) {
//         callbackConfirm && callbackConfirm()
//       } else {
//         callbackCancel && callbackCancel()
//       }
//     })
//   }
//
//
//   return {
//     popup
//   }
// }
//
//
// export default useSwal
