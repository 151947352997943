import React from "react";
import PropTypes from "prop-types";
import './style.scss'


const BusinessHours = ({bookable}) => {

  /**
   *
   * @param day
   * @returns {boolean}
   */
  const isOpen = (day) => {
    if (bookable.business_hour && bookable.business_hour[day + '_start']) {
      return true
    }
  }

  /**
   *
   * @param day
   * @returns {string}
   */
  const getClass = (day) => {
    return (isOpen(day)) ? 'open' : 'closed'
  }

  /**
   *
   * @param day
   * @returns {*|string}
   */
  const getStartTime = (day) => {
    return (isOpen(day)) ? bookable.business_hour[day + '_start'] : ''
  }

  /**
   *
   * @param day
   * @returns {*|string}
   */
  const getEndTime = (day) => {
    return (isOpen(day)) ? bookable.business_hour[day + '_end'] : ''
  }

  /**
   * Check if business hours should be shown
   *
   * @returns {boolean}
   */
  const shouldShow = () => {
    let isOpen = false
    bookable.business_hour && Object.keys(bookable.business_hour).map((key, index) => {
      if (key !== 'id' && bookable.business_hour[key] !== null && bookable.business_hour[key] !== ''){
        isOpen = true
      }
    })
    return isOpen
  }

  return (
    <>
      {shouldShow() &&
        <div className="component-business-hours">
          <div className="container">
            <div className="days">
              <div className={"day " + getClass('mo')}>Ma</div>
              <div className={"day " + getClass('tu')}>Di</div>
              <div className={"day " + getClass('we')}>Wo</div>
              <div className={"day " + getClass('th')}>Do</div>
              <div className={"day " + getClass('fr')}>Vr</div>
              <div className={"day " + getClass('sa')}>Za</div>
              <div className={"day " + getClass('su')}>Zo</div>
            </div>
            <div className="start-time">
              <div className="time">{getStartTime('mo')}</div>
              <div className="time">{getStartTime('tu')}</div>
              <div className="time">{getStartTime('we')}</div>
              <div className="time">{getStartTime('th')}</div>
              <div className="time">{getStartTime('fr')}</div>
              <div className="time">{getStartTime('sa')}</div>
              <div className="time">{getStartTime('su')}</div>
            </div>
            <div className="end-time">
              <div className="time">{getEndTime('mo')}</div>
              <div className="time">{getEndTime('tu')}</div>
              <div className="time">{getEndTime('we')}</div>
              <div className="time">{getEndTime('th')}</div>
              <div className="time">{getEndTime('fr')}</div>
              <div className="time">{getEndTime('sa')}</div>
              <div className="time">{getEndTime('su')}</div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

BusinessHours.propTypes = {
  bookable: PropTypes.object.isRequired,
}

export default BusinessHours
