// @note needs to be synced with the backend functions
// BookingStatusEnum

const bookingStatusEnum = [
  {
    label:'Concept',
    value: 'concept', //not yet requested, not sent out
  },
  {
    label:'Aangevraagd',
    value: 'requested', //negotiation
  },
  {
    label:'Geaccepteerd',
    value: 'accepted', //waiting for payment (not confirmed)
  },
  {
    label:'Afgewezen',
    value: 'rejected',
  },
  {
    label:'Betaald & definitief',
    value: 'paid', //confirmed
  },
  {
    label:'Geannuleerd',
    value: 'canceled', //canceled by requester or by subject after accepting or payment. Maybe give some money back
  },
  {
    label:'Afgerond',
    value: 'completed', //time based event, after booking end_date has passed
  },
];

export default bookingStatusEnum
