import React from "react";
import PropTypes from "prop-types";
import Media from "../Media/Media";
import "./SelectBookable-style.scss"

const SelectBookable = (props) => {

  const {
    label = '',
    name = '',
    bookableName,
    className = '',
    bookables,
    hasError = false,
    error = '',
    formData,
    save,
  } = props

  /**
   *
   * @param modelId
   * @returns {*|boolean}
   */
  const isChecked = (modelId) => {
    return !!(formData.model[name] && parseInt(formData.model[name]) === parseInt(modelId))
  }

  /**
   *
   * @param bookableId
   */
  const onChangeModel = (bookableId) => {
    formData.model[name] = bookableId
    if (bookableName) {
      formData.model[bookableName] = bookables.find(bookable => bookable.id === parseInt(bookableId))
    }
    save()
  }

  return (
    <div className={className + 'select-bookable '}>
      <div>{label}
        {hasError && <span className="error"> - {error}</span>}
        <>
          {bookables.map((bookable, i) => {
            return (
              <div key={i}>
                <label>
                  <div className="model">

                    {bookable.overview_media &&
                      <div className="col image">
                        <Media media={bookable.overview_media} size={'logo'}/>
                      </div>
                    }

                    <h3 className="col label">
                      {bookable.label}
                    </h3>
                    <div className="col radio">
                      <input
                        name={name}
                        type="radio"
                        onChange={() => onChangeModel(bookable.id)}
                        checked={isChecked(bookable.id)}
                      />
                    </div>
                  </div>
                </label>
              </div>
            )
          })}
        </>
      </div>
    </div>
  )
}

SelectBookable.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  bookableName: PropTypes.string.isRequired,
  className: PropTypes.string,
  bookables: PropTypes.array.isRequired,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
}

export default SelectBookable
