import React, {useEffect, useState} from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TelegramShareButton,
  LinkedinIcon,
  TelegramIcon,
  EmailIcon,
  XIcon,
} from "react-share";
import PropTypes from "prop-types";
import useBookable from "../../hooks/useBookable";
import './style.scss'
import useData from "../../hooks/useData";


const SocialShareProfile = (props) => {

  const [bookable, setBookable] = useState(null)
  const {model, formData} = props
  const {isStand} = useBookable()
  const {isPublic} = useBookable()
  const {getIndustryById, getTypeById} = useData()

  useEffect(() => {
    setBookable((formData && 'model' in formData && formData.model)
      ? formData.model
      : model
    )
  }, [formData])

  const getLabel = () => {
    return bookable.label
    // return (isStand(model))
    //   ? bookable.label
    //   : bookable.label
  }

  const getLink = () => {
    return (isStand(model))
      ? 'https://standplaats.nl/stand/' + bookable.slug
      : 'https://standplaats.nl/locatie/' + bookable.slug
  }

  const getMessage = () => {
    return (isStand(model))
      ? 'Bekijk mijn stand of truck! ' + model.description
      : 'Bekijk mijn locatie! ' + model.description
  }

  const getHashtags = () => {
    if (isStand(model)){
      return model.industries_ids.map(id => getIndustryById(id).label.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, ''))
    } else {
      return model.types_ids.map(id => getTypeById(id).label.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, ''))
    }
  }

  return (
    <div className="component-social-share-profile">
      {bookable && isPublic(bookable) &&
        <>
          <p>Deel je profiel op jouw social media accounts om het onder de aandacht te brengen!</p>

          <FacebookShareButton
            url={getLink()}
            quote={getLabel()}
            hashtag={getHashtags()}
          >
            <FacebookIcon size={32} round/>
          </FacebookShareButton>

          <TwitterShareButton
            title={getLabel()}
            url={getLink()}
            hashtags={getHashtags()}
            // related={""}
          >
            <XIcon size={32} round/>
          </TwitterShareButton>

          <EmailShareButton
            subject={getLabel()}
            body={getMessage()}
            url={getLink()}
          >
            <EmailIcon size={32} round/>
          </EmailShareButton>

          <WhatsappShareButton
            title={getLabel()}
            separator={" "}
            url={getLink()}
          >
            <WhatsappIcon size={32} round/>
          </WhatsappShareButton>

          {/*<FacebookMessengerShareButton */}
          {/*  appId={}*/}
          {/*  url={getLink()}*/}
          {/*>*/}
          {/*  <FacebookMessengerIcon/>*/}
          {/*</FacebookMessengerShareButton>*/}

          <LinkedinShareButton
            title={getLabel()}
            summary={getMessage()}
            source={getLink()}
            url={getLink()}
          >
            <LinkedinIcon size={32} round/>
          </LinkedinShareButton>

          <TelegramShareButton
            title={getLabel()}
            url={getLink()}
          >
            <TelegramIcon size={32} round/>
          </TelegramShareButton>

        </>
      }
    </div>
  )
}

SocialShareProfile.prototype = {
  model: PropTypes.object.isRequired,
}

export default SocialShareProfile
