import React from "react";
import PropTypes from "prop-types";
import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin"


const AnchorIndex = ({titles = []}) => {

  gsap.registerPlugin(ScrollToPlugin);

  const handleClick = (anchorId) => {
    gsap.to(window, {
      duration: 1.2,
      ease: "power4.inOut",
      scrollTo:{
        y: '#anchor-' + anchorId,
        offsetY:270
      }
    });
  }

  return (
    <div
      className="component-anchor-index"
    >
      {titles.map((title, i) => {
        return (
          <div
            className="anchor-title"
            key={i}
            onClick={() => handleClick(title.id)}
          >
            {title.label}
          </div>
        )
      })}
    </div>
  )
}
AnchorIndex.propTypes = {
  titles: PropTypes.array,
}

export default AnchorIndex
