import React, {useEffect, useState} from "react";
import {gsap} from "gsap";
import initialFilter from "../../data/initialFilter";
import MapBookables from "../MapBookables/MapBookables";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useBookableService from "../../api/services/useBookableService";
import useAlertService from "../../api/services/useAlertService";
import SquareBookable from "../SquareBookable/SquareBookable";
import useQueryString from "../../hooks/useQueryString";
import SearchFilter from "../SearchFilter/SearchFilter";
import useFunction from "../../hooks/useFunction";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import swal from "sweetalert";
import alarmWhiteIcon from "../../assets/icons/alarm-white.svg";
import {DateObject} from "react-multi-date-picker";
import arrowUpIcon from "../../assets/icons/arrow-up.svg";
import blocksIcon from "../../assets/icons/blocks.svg";
import cloneDeep from 'lodash/cloneDeep';
import sortIcon from "../../assets/icons/sort.svg";
import mapIcon from "../../assets/icons/map.svg";
import "./style.scss"

const ListBookables = () => {
  const {update: updateURL, getAll: getAllURL} = useQueryString()
  const [isChanged, setIsChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false)
  const [bookables, setBookables] = useState([])
  const [showMap, setShowMap] = useState(false)
  const [showSortDropdown, setShowSortDropdown] = useState(false)
  const [noMoreResults, setNoMoreResults] = useState(false)
  const [isLoadingAddAlert, setIsLoadingAddAlert] = useState(false)
  const {userStore: storeAlert} = useAlertService()
  const {setWithPath} = useFunction()
  const {show} = useBookableService()
  const [isMobile, setIsMobile] = useState(false)
  const [nCalendars, setNCalendars] = useState(2)
  const {width} = useWindowDimensions();
  const cloneInitialFilter = cloneDeep(initialFilter)
  const [filter, setFilter] = useState(cloneInitialFilter)

  useEffect(() => {
    if (width > 1000 && nCalendars !== 2) {
      setNCalendars(2)
    } else if (width <= 1000 && nCalendars !== 1) {
      setNCalendars(1)
    }
    if (width < 768 && !isMobile) {
      setIsMobile(true)
    } else if (width >= 768 && isMobile) {
      setIsMobile(false)
    }
  }, [width])


  useEffect(() => {
    getAllURL().map((item) => {
      setFilterByPath(item.key, item.value)
    })

  }, [])

  /**
   * Get bookables on mount
   */
  useEffect(() => {
    getBookables(filter)
  }, [showMap])

  /**
   *
   */
  useEffect(() => {
    setIsLoadingAddAlert(false)
  }, [isChanged]);
  /**
   * Get bookables
   *
   * @param filter
   * @param scroll
   */
  const getBookables = (filter, scroll = false) => {
    if (scroll) {
      if (isMobile) {
        gsap.to(window, {duration: 0.5, scrollTo: {y: ".tiles-container", offsetY: 180}, ease: "power2.inOut"});
      } else {
        window.scrollTo(0, 0);
      }
    }
    !isLoading && setIsLoading(true)
    let newFilter = filter
    newFilter.page = (showMap) ? 'map' : 0
    setFilter(newFilter)
    show(
      (bookables) => {
        if (bookables.length === 0) {
          setBookables([])
          setNoMoreResults(true)
        } else {
          if (bookables.length < 9) {
            setNoMoreResults(true)
          }
          setBookables(bookables)
        }
        setIsLoading(false)
      },
      () => {
      },
      () => {
        setIsLoading(false)
        setBookables([])
        setNoMoreResults(true)
      },
      parseFilter(filter)
    )
    setNoMoreResults(false)
  }


  /**
   * Search (or not)
   *
   * @param filter
   */
  const search = (filter) => {
    //todo could be a bit better: could use object comparison.
    // the page should reload after switch stand/spot_group

    if (isChanged) {
      getBookables(filter, true)
      setIsChanged(false)
    }
  }


  const parseFilter = (filter) => {
    const parsedFilter = {...filter}

    if (
      parsedFilter.when &&
      Array.isArray(parsedFilter.when) &&
      parsedFilter.when.length &&
      parsedFilter.when[0] instanceof DateObject
    ) {
      parsedFilter.when = parsedFilter.when.map(date => date.format("YYYY-M-D").toString())
    }
    return parsedFilter
  }


  /**
   * Get title
   *
   * @returns {string}
   */
  const getTitle = () => {
    let title = 'Standplaatsen < > Stands & trucks'
    if (filter.direction === 'search') {
      if (filter.search_model === 'stand') {
        title = 'Beschikbare stands & trucks'
      } else if (filter.search_model === 'spot_group') {
        title = 'Beschikbare standplaatsen'
      }
    } else if (filter.direction === 'offer') {
      if (filter.offer_model === 'stand') {
        title = 'Beschikbare standplaatsen'
      } else if (filter.offer_model === 'spot_group') {
        title = 'Beschikbare stands & trucks'
      }
    }
    return title
  }

  /**
   * Load next page
   */
  const loadNextPage = () => {

    let newFilter = filter
    newFilter.page++
    setFilter(newFilter)
    setIsLoadingNextPage(true)

    show(
      (newBookables) => {
        if (newBookables.length === 0) {
          setNoMoreResults(true)
        } else {
          if (newBookables.length < 9) {
            setNoMoreResults(true)
          }
          const oldBookables = bookables
          oldBookables.push(...newBookables)
          setBookables(oldBookables)
        }
        setIsLoadingNextPage(false)
      },
      () => {
      },
      () => {
        // setIsLoading(false)
        // setBookables([])
        // setNoMoreResults(true)
      },
      parseFilter(newFilter)
    )
  }

  /**
   * Set value of filter via path string
   *
   * @param path
   * @param value
   * @param andSearch
   */
  const setFilterByPath = (path, value, andSearch = false) => {
    updateURL(path, value)
    const newFilter = setWithPath(filter, path, value)
    setFilter({...filter, ...newFilter})
    if (andSearch) {
      getBookables(newFilter)
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
    return newFilter
  }


  /**
   * //todo analyse the complete asynchronicity of all filter functions: should be better
   * // make sure the filter, isChanged, showMap and other states are in updated when used..
   * @param value
   */
  const setFilterSort = (value) => {
    setFilterByPath('sort', value, true)
    setShowSortDropdown(false)
  }

  const handleListSwitch = () => {
    setShowMap(!showMap)
  }

  const scrollToTop = () => {
    gsap.to(window, {duration: 0.5, scrollTo: 0, ease: "power2.inOut"});
  }


  const handleRegisterAlert = () => {
    setIsLoadingAddAlert(true)
    storeAlert(
      (data) => {
        swal({
          title: 'Alert toegevoegd',
          icon: 'success',
          timer: 1850,
          buttons: false,
        })
        setIsLoadingAddAlert(true)
      },
      (errors) => {

        setIsLoadingAddAlert(true)
      },
      {filter: parseFilter(filter)}
    )
  }

  return (
    <div className="component-list-bookbales">
      {/*<section className="industries">*/}
      {/*  <div className="section-container">*/}
      {/*    {mainIndustries.map((item, i) => {*/}
      {/*      return (*/}
      {/*        <div key={i} className="industry" onClick={item.onClick}>*/}
      {/*          <img className={('iconSmall' in item && item.iconSmall) ? 'small' : ''} src={item.icon}*/}
      {/*               alt={getIndustry(item.industryId).label}/>*/}
      {/*          <div className="label">{('altLabel' in item) ? item.altLabel : getIndustry(item.industryId).label}</div>*/}
      {/*        </div>*/}
      {/*      )*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<div className="mobile-header">*/}
      {/*  <h1>{getTitle()}</h1>*/}
      {/*</div>*/}


      <div className={(showMap) ? "content show-map" : "content"}>
        <div className={(showMap) ? "sidebar show-map" : "sidebar"}>
          <SearchFilter
            filter={filter}
            isChanged={isChanged}
            search={search}
            showMap={showMap}
            setFilterByPath={setFilterByPath}
            isLoading={isLoading}
            nCalendars={nCalendars}
            isMobile={isMobile}
          />
        </div>
        <div className="main-content">
          <div className="top-row">
            <h1>{getTitle()}</h1>
            <div className="filter-buttons">
              <Button
                label={(showMap) ? "Lijst" : "Kaart"}
                size={isMobile?'s':'l'}
                icon={(showMap) ? blocksIcon : mapIcon}
                color="special"
                onClick={handleListSwitch}
              />
              {!showMap &&
                <>
                  <Button
                      label="Sorteren"
                      size={isMobile?'s':'l'}
                      icon={sortIcon}
                      color="special"
                      onMouseEnter={() => setShowSortDropdown(true)}
                      onMouseLeave={() => setShowSortDropdown(false)}
                  >
                    {showSortDropdown &&
                      <div className="sort-menu">
                        <ul>
                          <li
                            onClick={() => setFilterSort('price_asc')}
                            className={(filter.sort === 'price_asc') ? 'active' : ''}
                          >Prijs: Laag-Hoog
                          </li>
                          <li
                            onClick={() => setFilterSort('price_desc')}
                            className={(filter.sort === 'price_desc') ? 'active' : ''}
                          >Prijs: Hoog-Laag
                          </li>

                          <li
                            onClick={() => setFilterSort('rating_desc')}
                            className={(filter.sort === 'rating_desc') ? 'active ' : ''}
                          >Beoordeling
                          </li>
                          <li
                            onClick={() => setFilterSort('distance_asc')}
                            className={
                              (filter.sort === 'distance_asc') ? 'active ' : '' +
                              (filter.where.postal_code || filter.where.city) ? '' : ' disabled '}
                          >Afstand
                          </li>
                          <li
                            onClick={() => setFilterSort('created_at_desc')}
                            className={(filter.sort === 'created_at_desc') ? 'active' : ''}
                          >Laatst aangemaakt
                          </li>
                          <li
                            onClick={() => setFilterSort('updated_at_desc')}
                            className={(filter.sort === 'updated_at_desc') ? 'active' : ''}
                          >Laatst Geupdate
                          </li>
                        </ul>
                      </div>
                    }
                  </Button>
                </>
              }
            </div>
          </div>
          {showMap && bookables ?
            <div className="map-component-container">
              <MapBookables
                bookables={bookables}
                isLoading={isLoading}
                filter={filter}
                setFilter={setFilter}
                setFilterByPath={setFilterByPath}
              />
            </div>
            :
            <div className="tiles-container">
              <div className="tiles">
                {isLoading
                  ?
                  <Loading/>
                  :
                  <>
                    {bookables && bookables.map((bookable, i) => {
                      return (
                        <SquareBookable
                          key={i}
                          bookable={bookable}
                        />
                      )
                    })}
                    {noMoreResults ?
                      <>
                        <div className="filler"></div>
                      </>
                      :
                      <>
                        {isLoadingNextPage ?
                          <Loading/>
                          :
                          <div className="buttons">
                            {filter.page > 0 ?
                              <Button
                                label="Omhoog"
                                size="l"
                                color="special"
                                onClick={scrollToTop}
                                icon={arrowUpIcon}
                                iconSize={22}
                              />
                              :
                              <div></div>
                            }
                            <Button
                              label="Bekijk meer"
                              size="l"
                              onClick={loadNextPage}
                            />
                          </div>
                        }
                      </>
                    }
                  </>
                }
              </div>
              {noMoreResults &&
                <div className="no-more-results">
                  <h4 className="title">Dat was het! Pas de filters aan voor meer resultaten...</h4>
                  {bookables.length <= 50 &&
                    <>
                      <h3 className="title">Niet gevonden wat je zoekt?</h3>
                      <div className="text">Ontvang meldingen via email wanneer er nieuwe stand & trucks beschikbaar
                        komen
                        met deze filters
                      </div>
                      <div className="button">
                        <Button
                          label="Alert melding ontvangen"
                          icon={alarmWhiteIcon}
                          disabled={isLoadingAddAlert}
                          onClick={handleRegisterAlert}
                          color="grey"
                        />
                      </div>
                      <br/>
                      <Button
                        color="transparent"
                        label="Beheer bestaande meldingen"
                        link="/user/zoek-alerts"
                      />
                    </>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}
const areEqual = (prevProps, nextProps) => {
  return true
}
export default React.memo(ListBookables, areEqual)
