import React, {useEffect, useState} from "react";
import spotGroupIcon from "../../assets/icons/spot-group-white.svg";
import initialBooking from "../../data/initialBooking";
import {useAppContext} from "../../context/AppContextProvider";
import {useParams} from "react-router-dom";
import useSpotGroupService from "../../api/services/useSpotGroupService";
import useBookable from "../../hooks/useBookable";
import useBooking from "../../hooks/useBooking";
import Error404 from "../../components/Error404/Error404";
import Loading from "../../components/Loading/Loading";
import Profile from "../../components/Profile/Profile";
import Button from "../../components/Button/Button";
import './style.scss'


function SpotGroup() {

  const {show} = useSpotGroupService()
  const {spotGroupSlug} = useParams();
  const [error, setError] = useState(null);
  const {isSpotGroup} = useBookable()


  const [booking, setBooking] = useState(initialBooking)


  //
  // const {
  //   booking,
  //   setBooking,
  //   //spotGroup,
  //   //setSpotGroup
  // } = useAppContext()
  //


  const {parseSpotGroupBookings} = useBooking()
  const [spotGroup, setSpotGroup] = useState(null)

  useEffect(() => {
    loadSpotGroup()
  }, [])

  const loadSpotGroup = () => {
    setSpotGroup(null) //removes old SpotGroup from Context
    show(
      (spotGroup) => {
        if (isSpotGroup(spotGroup)) {
          spotGroup = parseSpotGroupBookings(spotGroup)
          setBooking({
            ...initialBooking,
            type: 'stand-requests-spot',
            received_by_company_id: spotGroup.company.id,
            spot_group: spotGroup,
            spot_group_id: spotGroup.id,
            spots: (spotGroup.multi_spot) ? [] : [spotGroup.spots[0]],
          })
        }
        setSpotGroup(spotGroup)
      },
      (errors) => {
      },
      spotGroupSlug,
      (error) => {
        if (error.status === 404) {
          setError(error)
        }
      }
    )
  }


  return (
    <div className="page-spot-group">
      {!spotGroup && !error &&
        <>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <Loading/>
        </>
      }
      {spotGroup &&
        <Profile
          bookable={spotGroup}
          setBookable={setSpotGroup}
          booking={booking}
          setBooking={setBooking}
          reloadBookable={loadSpotGroup}
        />
      }
      {error &&
        <Error404
          label="Locatie niet gevonden"
          text="Mogelijk is deze locatie niet meer beschikbaar."
          button={<Button
            label={'Alle locaties'}
            link={'/?direction=search&search_model=spot_group'}
            icon={spotGroupIcon}
          />}
        />
      }
    </div>
  )
}

export default SpotGroup
