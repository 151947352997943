import React, {useEffect, useState} from "react";
import useFavoriteService from "../../api/services/useFavoriteService";
import Tile from "../../components/Tile/Tile";
import Loading from "../../components/Loading/Loading";

const UserFavorites = () => {

  const {userShowAll} = useFavoriteService()
  const [stands, setStands] = useState([])
  const [spotGroups, setSpotGroups] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    userShowAll(
      (data) => {
        if ('stands' in data) {
          setStands(data.stands)
        }
        if ('spot_groups' in data) {
          setSpotGroups(data.spot_groups)
        }
        setIsLoading(false)
      }
    )
  }, [])

  return (
    <div>
      <h1>Favorieten</h1>
      {isLoading ?
        <Loading/>
        :
        <>
          {stands &&
            <>
              <h2>Stands & Trucks</h2>
              {stands.length ?
                stands.map((stand, i) => {
                  return (
                    <div key={i}>
                      <Tile
                        bookable={stand}
                      />
                    </div>
                  )
                })
                :
                <p>Geen favorite stands & trucks</p>
              }
            </>
          }
          {spotGroups &&
            <>
              <h2>Locaties</h2>
              {spotGroups.length ?
                spotGroups.map((spotGroup, i) => {
                return (
                  <div key={i}>
                    <Tile
                      bookable={spotGroup}
                    />
                  </div>
                )
              })
                :
                <p>Geen favorite locaties</p>
              }
            </>
          }
        </>
      }
    </div>
  )
}

export default UserFavorites
