import useFunction from "../../hooks/useFunction";
import useApi from "../../api/useApi";
import {useContext} from "react";
import AuthContext from "../../context/AuthContext";

const useUserService = () => {

  const {c} = useFunction()
  const {api} = useApi()
  const {setUser} = useContext(AuthContext)

  const updateUserAfterSuccess = (setUser, callbackSuccess, responseData) => {
    //todo add check to see 'object_type'

    setUser(responseData)
    callbackSuccess(responseData)
  }

  const userShow = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user',
      response => updateUserAfterSuccess(setUser, callbackSuccess, response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in show service', error),
    )
  }

  const userShowDashBoard = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'user/dashboard',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in show service', error),
    )
  }

  const userUpdate = (callbackSuccess, callbackValidationErrors, modelId, data) => {
    return api(
      'post',
      'user/update',
      response => updateUserAfterSuccess(setUser, callbackSuccess, response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in update service', error),
      data,
    )
  }

  const userEmailChange = (callbackSuccess, callbackValidationErrors, modelId, data) => {
    return api(
      'post',
      'user/email/change',
      response => updateUserAfterSuccess(setUser, callbackSuccess, response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in userEmailChange service', error),
      data,
    )
  }

  const userPasswordChange = (callbackSuccess, callbackValidationErrors, modelId, data) => {
    return api(
      'post',
      'user/password/change',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in userPasswordChange service', error),
      data,
    )
  }

  const userSwitchActiveCompany = (callbackSuccess, callbackValidationErrors, activeCompanyId) => {
    return api(
      'post',
      'user/switch-active-company/' + activeCompanyId,
      response => updateUserAfterSuccess(setUser, callbackSuccess, response.data),
      errors => callbackValidationErrors(errors),
      error => c('Error in userPasswordChange service', error),
    )
  }

  return {
    objectType: 'user',
    userShow,
    userShowDashBoard,
    userUpdate,
    userEmailChange,
    userPasswordChange,
    userSwitchActiveCompany,
  }
}
export default useUserService
