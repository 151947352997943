import React from "react";
import PropTypes from "prop-types";
import "./style.scss"

const FormButtons = (props) => {
  const {
    isChanged,
    buttonCancelLabel = "Annuleren",
    buttonConfirmLabel = "Opslaan",
    handleCancel,
    handleConfirm,
    disabledConfirm,
    disabledCancel,
    isFixed = true,
  } = props

  return (
    <div className={(isFixed)?"component-form-buttons is-fixed":"component-form-buttons"}>
      <div className={isChanged ? 'bottom-container is-changed' : 'bottom-container'}>
        <button
          value={buttonCancelLabel}
          className="btn-size-l btn-color-red"
          type='reset'
          onClick={handleCancel}
          disabled={disabledCancel}
        >{buttonCancelLabel}
        </button>
        <button
          value={buttonConfirmLabel}
          className="btn-size-l btn-color-green"
          type='submit'
          onClick={handleConfirm}
          disabled={disabledConfirm}
        >{buttonConfirmLabel}
        </button>
      </div>
    </div>
  )
}

FormButtons.propTypes = {
  isFixed: PropTypes.bool,
  buttonConfirmLabel: PropTypes.string,
  buttonCancelLabel: PropTypes.string,
  isChanged: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  disabledConfirm: PropTypes.bool,
  disabledCancel: PropTypes.bool,
};

export default FormButtons
