import React from "react";
import PropTypes from "prop-types";
import useForm from "../../hooks/useForm";
import './Input-style.scss'

const Input = (props) => {

  const {getValue} = useForm()

  const {
    label = '',
    name = '',
    text = '',
    value = '',
    className = '',
    hasError =  false,
    onChange = () => {},
    error = '',
    isLoading,
    type = 'text',
    placeholder = '',
    disabled,
    autoFocus = '',
  } = props

  let realType = type
  let step = null
  let lang = null
  let style = {}
  let afterLabel = null

  if (type === 'meter'){
    realType = 'number'
    step="0.1"
    lang="nl-NL"
    afterLabel = 'Meter'
    style = {
      maxWidth:100,
    }
  }

  if (type === 'graden'){
    realType = 'number'
    step="1"
    lang="nl-NL"
    afterLabel = 'Graden'
    style = {
      maxWidth:100,
    }
  }

  if (type === 'km'){
    realType = 'number'
    step="1"
    lang="nl-NL"
    afterLabel = 'Kilometer (KM)'
    style = {
      maxWidth:100,
    }
  }

  return (
    <div className={className+ 'input '}>
      <label>{label}
        {text && text !== '' &&
          <p className="text-small">{text}</p>
        }
        {hasError && <span className="error"> - {error}</span>}
        <div className="form-row">
          <input
            style={style}
            disabled={isLoading || disabled}
            type={realType}
            name={name}
            value={getValue(value)}
            onChange={onChange}
            step={step}
            lang={lang}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
        {afterLabel &&
          <span className="after-label">{afterLabel}</span>
        }
        </div>
      </label>
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  autoFocus:PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}

export default Input
