import React, {useContext, useEffect, useState} from "react"
import AuthContext from "../../context/AuthContext";
import UserProgressTable from "../../components/ProgressTable/UserProgressTable";
import useAdminService from "../../api/services/useAdminService";
import AdminNav from "../../components/AdminNav/AdminNav";
import Loading from "../../components/Loading/Loading";
import './style.scss'


const UserAdminProgress = () => {
  const {user} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const {adminShowCompanyUserProgress} = useAdminService()

  useEffect(() => {
    adminShowCompanyUserProgress(
      (data) => {
        console.log('data.table', data.table) //todo remove
        setData(data.table);
        setIsLoading(false)
      },
      (errors) => {
        setIsLoading(false)
      },
    )
  }, []);

  return (
    <div className="page-user-admin">
      {user && user.is_admin &&
        <>
          <AdminNav/>
          <h4>Check user flow progress:</h4>
          {isLoading
            ? <Loading/>
            : <UserProgressTable data={data}/>
          }
        </>
      }
    </div>
  )
}

export default UserAdminProgress