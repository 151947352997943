import React, {useState} from "react";
import useCompanyService from "../../api/services/useCompanyService";
import useFunction from "../../hooks/useFunction";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import swal from "sweetalert";
import "./CompanyUsers-style.scss";

const CompanyUsers = (props) => {

  const {
    formData,
    save,
  } = props
  const {dateTime} = useFunction()
  const {userAddUser} = useCompanyService()
  const [email, setEmail] = useState('')
  const [validationErrors, setValidationErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const addUser = () => {
    swal({
      title: "Gebruiker met email '" + email + "' volledig toegang geven?",
      icon: 'warning',
      text: "U staat op het punt om '" + email + "' volledige toegang te verleden tot dit account/deze organisatie. Als dit mail adres nog niet geregistreerd is op Standplaats.nl zal er een uitnodigingsmail verstuurd worden.",
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Annuleren',
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-color-red btn-size-m',
          closeModel: true,
        }
      }
    }).then((result) => {
      if (result) {

        setIsLoading(true)
        userAddUser(
          (data) => {
            if (data.company_user && data.action === 'added'){
              formData.model.company_users.push(data.company_user)
              swal({
                title: 'Gebruiker gekoppeld',
                icon: 'success',
                timer: 650,
                buttons: false,
              })
            }
            if (data.action === 'invited'){
              formData.model.user_invites = data.user_invites
              swal({
                title: 'Gebruiker uitgenodigd',
                icon: 'success',
                timer: 650,
                buttons: false,
              })
            }
            if (data.action === 'already-added'){
              swal({
                title: 'Gebruiker al gekoppeld',
                icon: 'warning',
                timer: 650,
                buttons: false,
              })
            }
            setEmail('')
            setIsLoading(false)
          },
          (errors) => {
            setValidationErrors(errors)
            setIsLoading(false)
          },
          formData.model.id,
          {email: email}
        )
      }
    })
  }

  const handleChange = (e) => {
    if (validationErrors) {
      setValidationErrors(null)
    }
    setEmail(e.target.value)
  }

  const hasError = (field) => {
    return validationErrors && field in validationErrors && validationErrors[field]
  }

  const handleRemove = (companyUser) => {
    swal({
      title: "Medewerker ontkoppelen?",
      icon: 'warning',
      text: "Je staat op het punt deze medewerker te verwijderen van deze organisatie. Dit wordt pas echt definitief nadat u op OPSLAAN (onderaan deze pagina) geklikt heeft.",
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Annuleren',
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-color-red btn-size-m',
          closeModel: true,
        }
      }
    }).then((result) => {
      if (result) {
        companyUser.is_trash = true
        save()
      }
    })
  }

  const handleRevoke = (userInvite) => {
    swal({
      title: "Uitnodiging intrekken??",
      icon: 'warning',
      text: "",
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Annuleren',
          value: null,
          visible: true,
          className: 'btn-color-grey btn-size-m',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn-color-red btn-size-m',
          closeModel: true,
        }
      }
    }).then((result) => {
      if (result) {
        userInvite.is_trash = true
        save()
      }
    })
  }

  return (
    <div className="form-component-company-users">

      <h3>Medewerkers en gebruikers:</h3>
      <div className="users">
        {formData.model.company_users.map((companyUser, i) => {
          return (
            <div className={(companyUser.is_trash) ? "user row is_trash" : "user row"} key={i}>
              <div className="col name">{companyUser.user.first_name}</div>
              <div className="col name">{companyUser.user.last_name}</div>
              <div className="col email">{companyUser.user.email}</div>
              <div className="col level">{(companyUser.user_level) ? companyUser.user_level : 'standaart'}</div>
              <div className="col invoke">
                {companyUser.is_trash ?
                  <Button
                    label="Terugdraaien"
                    color="grey"
                    size="xs"
                    onClick={() => {
                      companyUser.is_trash = false
                      save()
                    }}
                  />
                  :
                  <Button
                    label="Gebruiker ontkoppelen"
                    color="red"
                    size="xs"
                    onClick={() => handleRemove(companyUser)}
                  />
                }
              </div>
            </div>
          )
        })}
      </div>
      <h3>Openstaande uitnodigingen:</h3>

      <div className="invites">
        {formData.model.user_invites.length === 0 ?
          <p>Geen openstaande uitnodigingen<br/><br/></p>
          :
          <>
            {formData.model.user_invites.map((userInvite, i) => {
              return (
                <div className={(userInvite.is_trash) ? "user row is_trash" : "user row"} key={i}>
                  <div className="col email">
                    {userInvite.email}
                  </div>
                  <div className="col date">
                    {dateTime(userInvite.created_at)}
                  </div>
                  <div className="col invoke">
                    {userInvite.is_trash ?
                      <Button
                        label="Terugdraaien"
                        color="grey"
                        size="xs"
                        onClick={() => {
                          userInvite.is_trash = false
                          save()
                        }}
                      />
                      :
                      <Button
                        label="Uitnodiging intrekken"
                        color="red"
                        size="xs"
                        onClick={() => handleRevoke(userInvite)}
                      />
                    }
                  </div>
                </div>
              )
            })}
          </>
        }
      </div>

      <h3>Gebruiker toevoegen aan dit account/deze organisatie:</h3>
      <div className="invite">
        {isLoading ?
          <Loading/>
          :
          <div className={hasError('email') ? 'has-error' : ''}>
            {hasError('email') &&
              <>
                {validationErrors.email.map((error, i) => {
                  return (<span key={i} className="error">{error}</span>)
                })}
              </>
            }
            <div className="invite-form">
            <div className="input-field">
              <input
                placeholder="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className="button">
              <Button
                label="Uitnodigen"
                disabled={!!(!email || validationErrors)}
                onClick={() => addUser()}
                size="l"
              />
            </div>
            </div>
          </div>

        }
      </div>
    </div>
  )
}

export default CompanyUsers
