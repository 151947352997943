import React, {useState, useMemo, useEffect} from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import useUserService from "../../api/services/useUserService";
import './style.scss';

import userProgressStepEnum from "../../data/userProgressStepEnum";
import Button from "../Button/Button";
import swal from "sweetalert";
import UserMessages from "../../pages/UserMessages/UserMessages";

const columnHelper = createColumnHelper();
const UserProgressTable = ({data}) => {

  const {userSwitchActiveCompany} = useUserService();
  const [showUserMessages, setShowUserMessages] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    // count unique comapnies
    const uniqueCompanies = new Set();
    const uniqueUsers = new Set();

    data.forEach((row) => {
      if (row.object_type === "company_user") {
        uniqueCompanies.add(row.company_id);
      } else {
        uniqueUsers.add(row.id);
      }
    });

    console.log("Count companies:", uniqueCompanies.size, uniqueCompanies);
    console.log("Count users:", uniqueUsers.size, uniqueUsers);
  }, [data]);

  const toggleRow = (companyId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [companyId]: !prev[companyId],
    }));
  };

  const processedData = useMemo(() => {
    const groupedData = [];
    const userRows = [];
    const companyMap = {};

    data.forEach((row) => {
      if (row.object_type === "company_user") {
        const companyId = row.company_id;

        if (!companyMap[companyId]) {
          companyMap[companyId] = {
            id: companyId,
            label: row.label.split("|")[0],
            steps: {...row.steps},
            last_received_message_at: row.last_received_message_at,
            users: [],
            isGroup: true,
            meta: {}, // Init meta
          };
        } else {

          Object.keys(row.steps).forEach((step) => {
            companyMap[companyId].steps[step] ||= row.steps[step];
          });
        }
        companyMap[companyId].meta = row.meta;
        companyMap[companyId].users.push({...row, isGroup: false});
      } else {
        userRows.push(row);
      }
    });

    groupedData.push(...Object.values(companyMap));
    return [...userRows, ...groupedData];
  }, [data]);

  const flattenedData = useMemo(() => {
    const flattened = [];

    processedData.forEach((row) => {
      flattened.push(row);
      if (row.isGroup && expandedRows[row.id]) {
        flattened.push(...row.users);
      }
    });

    return flattened;
  }, [processedData, expandedRows]);

  const columns = [
    columnHelper.display({
      id: "expand",
      header: "",
      cell: ({row}) => {
        if (row.original.isGroup) {
          return (
            <>
              <button
                onClick={() => toggleRow(row.original.id)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "16px",
                  lineHeight: "1",
                  color: "black",
                }}
                title={expandedRows[row.original.id] ? "Collapse" : "Expand"}
              >
                {expandedRows[row.original.id] ? "▼" : "►"}
              </button>
              <Button
                size="xs"
                color="grey"
                label="Chat"
                onClick={() => {
                  swal({
                    title: "Aan het schakelen...",
                    text: "Een moment geduld",
                    icon: "info",
                    buttons: false,
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                  });
                  userSwitchActiveCompany(
                    () => {
                      // swal.close();
                      swal({
                        title: "Geswitched",
                        icon: "success",
                        timer: 1000,
                        buttons: false,
                      });
                      setShowUserMessages((row.original.label) ? row.original.label : true)
                    },
                    () => {
                      // swal.close();
                      swal({
                        title: "Er ging iets mis",
                        text: "Probeer het opnieuw",
                        icon: "error",
                        timer: 1000,
                        buttons: false,
                      });
                    },
                    row.original.id
                  );
                }}
              />
            </>
          );
        }
        return null;
      },
      enableSorting: false,
      enableResizing: false,
      size: 40,
    }),
    columnHelper.accessor("label", {
      header: "Name",
      cell: (info) => {
        const row = info.row.original;

        // Extract email or label
        const emailOrLabel = row.label.split("|")[1] || row.label;
        const startDate = new Date("2024-08-11");  // date when we start counting activity
        const today = new Date();
        const diffTime = today - startDate;
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        const statusIcons = {
          'not-requested': null, // Geen actie nodig, geen icoon
          'pending': <span title="Aangevraagd door klant, gaat instructiemail krijgen">⏳</span>,
          'instructions-sent-to-client': <span title="Instructiemail verstuurd, wachten op details van klant">📩</span>,
          'completed': <span title="Voltooid">✅</span>,
          'flagged-by-admin': <span title="Gemarkeerd door admin als ongeschikt">⚠️</span>,
          'take-over-suggestion-sent': <span title="Overname voorgesteld aan klant">🤝</span>,
          'not-interested': <span title="Klant heeft geen interesse">🚫</span>,
        };
        const SpotMapStatusIcons = ({ meta }) => {
          if (!meta?.requests_spot_map_creation_status?.length) return null;
          if (!meta.requests_spot_map_creation_status.filter(status => status !== 'not-requested').length){
            return
          }
          return (
            <>
              <span style={{fontWeight:"normal"}}>SpotMap status: </span>
              {meta.requests_spot_map_creation_status.map((status, index) =>
                statusIcons[status] ? <span key={index}>{statusIcons[status]}</span> : null
              )}
            </>
          );
        };
        // Extract company-specific fields
        const lastMessageFormatted = formatDaysAgo(row.last_received_message_at, "bericht ontvangen: ");
        const lastActivityFormatted = formatDaysAgo(row.last_activity_at);
        const companyName = row.isGroup ? row.label : null;
        // const showWarning = row.meta?.requests_spot_map_creation_status;

        return (
          <div>
            <div>{emailOrLabel}</div>
            {companyName && (
              <div>

                <SpotMapStatusIcons meta={row.meta} />

                {/*{showWarning && <span title="Spot map creation aanvraag">⚠️</span>}*/}


                {lastMessageFormatted && (
                  <div style={{fontSize: "12px", color: "blue"}}>
                    {lastMessageFormatted}
                  </div>
                )}
              </div>
            )}

            {!row.isGroup &&
              <div style={{fontSize: "12px", color: "red"}}>
                Activiteit: {lastActivityFormatted ? lastActivityFormatted : 'Meer dan ' + diffDays + ' dagen geleden'}
              </div>
            }
          </div>
        );
      },
    }),

    ...userProgressStepEnum.map((step) =>
      columnHelper.accessor((row) => row.steps?.[step.value], {
        id: step.value,
        header: step.label,
        cell: (info) => renderStep(info),
      })
    ),
  ];

  const table = useReactTable({
    data: flattenedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const calculateDaysAgo = (lastActivityAt) => {
    if (!lastActivityAt) return null;

    try {
      const lastActivityDate = new Date(lastActivityAt.replace(" ", "T"));
      const currentDate = new Date();
      const diffInTime = currentDate - lastActivityDate;

      if (isNaN(diffInTime)) return null;

      return Math.floor(diffInTime / (1000 * 60 * 60 * 24)); // Convert to days
    } catch (error) {
      return null;
    }
  };

  const formatDaysAgo = (dateString, prefix = "") => {
    const daysAgo = calculateDaysAgo(dateString);
    if (daysAgo === null) return null;
    return `${prefix}${daysAgo} dagen geleden`;
  };

  return (
    <div className="component-user-progress-table">
      {showUserMessages && (
        <div className="message-popup" onClick={() => setShowUserMessages(false)}>
          <div
            className="user-messages-container"
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              <h4>Chat van: {showUserMessages}</h4>
              <UserMessages/>
            </div>
          </div>
        </div>
      )}
      <table>
        <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} style={{textAlign: "center"}}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} className={row.original.isGroup ? "group-row" : ""}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

// Helper function to render ✔️ or -
const renderStep = (info) => {
  return info.getValue() ? (
    <span style={{color: "green"}}>✔️</span>
  ) : (
    <span style={{color: "gray"}}>-</span>
  );
};

export default UserProgressTable;
