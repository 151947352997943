import React from "react";
import PropTypes from "prop-types";

const Tab = (props) => {
  return (
    <div className="component-tab">
      {props.children}
    </div>
  )
}

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
}

export default Tab
