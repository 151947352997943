import React from "react";
import Button from "../Button/Button";
import PropTypes from "prop-types";
import './style.scss'

const RelationCheckboxes = (props) => {
  const {
    label = '',
    spotGroup,
    setSpotGroup,
    setIsChanged,
    mappable,
  } = props

  const key = 'spot_maps'

  const [forceRerender, setForceRerender] = React.useState(false)

  if (!(key in mappable)) {
    mappable[key] = []
  }

  /**
   *
   * @param id
   */
  const onChange = (id) => {
    const item = mappable[key].find(obj => obj.id === id)
    if (item) {
      mappable[key].splice(mappable[key].indexOf(item), 1)
    } else {
      mappable[key].push({id:id})
    }
    setSpotGroup(spotGroup)
    setIsChanged(true)
    setForceRerender(!forceRerender)
  }

  const selectAll = () => {
    const newValue = []
    spotGroup.spot_maps.map((spotMap) => {
      newValue.push({id:spotMap.id})
    })
    mappable[key] = newValue
    setSpotGroup({...spotGroup, spotGroup})
    setForceRerender(!forceRerender)
  }

  return (
    <div className="component-relation-checkboxes ">
      <div className="label">{label}
        <div className="form-row">
          {mappable[key].length
            ?
            <div className="items">
              {spotGroup.spot_maps.map((spotMap, i) => {
                return (
                  <label className="item" key={i}>
                    <div className="label">
                      {spotMap.label}
                    </div>
                    <input
                      type="checkbox"
                      name={spotMap.id}
                      defaultChecked={!!mappable[key].find(obj => obj.id === spotMap.id)}
                      onChange={() => onChange(spotMap.id)}
                    />
                  </label>
                )
              })}
            </div>
            :
            <div className="row">
              <span>Alle kaarten</span>
              <Button
                label="Aanpassen"
                color="grey-ghost"
                size="s"
                onClick={() => selectAll()}
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

RelationCheckboxes.propTypes = {
  label: PropTypes.string,
}

export default RelationCheckboxes
