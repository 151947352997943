const companyTypeEnum = [
  {
    label: 'Niet officeel / informeel / overig',
    value: 'non-official',
  },
  {
    label: 'Commercieel (BV, NV, ZZP, VOF, ...)',
    value: 'commercial',
  },
  {
    label: 'Publiek / overheid',
    value: 'public',
  },
  {
    label: 'Non profit',
    value: 'non-profit',
  },
];

export default companyTypeEnum