import React from "react";
import Button from "../Button/Button";

const AdminNav = () => {
  return(
    <div className="component-admin-nav">
      {/*<h1>Admin</h1>*/}
      <Button
        label="Admin Tools"
        link="/user/admin/tools"
      />
      <Button
        label="Users"
        link="/user/admin/users"
      />
      <Button
        label="User Progress"
        link="/user/admin/user-progress"
      />
      <hr/>
    </div>
  )
}
export default AdminNav
