import React from "react";
import {backendUrl} from "../../env";
import PropTypes from "prop-types";


const Media = (props) => {

  const {
    media,
    alt = null,
    className = null
  } = props
  let {size} = props

  const isRetina = false
  const isSmartPhone = true

  /*
   * thumb
   * logo
   * banner
   * tile
   * normal
   * normal_full_height
   */

  //todo implement retina.
  //todo also implement retina on other <img comp.)

  if (isRetina) {
    size += '_retina'
  }


  const getSrc = () => {
    if (media && 'value' in media){
      return media.value
    } else if (media) {
      if (media.type === 'svg') {
        return backendUrl + media.path.filename
      } else {
        return backendUrl + media.path[size]
      }
    }
  }

  return (
    <>
      {media &&
        <img
          src={getSrc()}
          alt={(alt) ? alt : media.alt}
          className={className}
        />
      }
    </>
  )
}

Media.propTypes = {
  media: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
}


export default Media
