import React from "react";

const BookingFinish = (props) => {
  const {
    className,
    value,
    formData,
  } = props

  const booking = formData.model

  return (
    <div className={className + 'booking-finish '}>

      {booking.direction === 'requesting' &&
        <>
          <p><strong>Bedankt voor de aanvraag!</strong></p>
          <p>Booking ID: {booking.booking_number}</p>
          {value === 'stand-requests-spot' &&
            <>
              <p>De locatie heeft jouw aanvraag ontvangen. Je krijgt via ons een bericht wanneer de locatie
                jouw aanvraag goedgekeurd heeft. Zorg ervoor dat je daarna direct betaald om de reservering helemaal
                compleet te maken!</p>
              <p>Je bent vrij om meerdere aanvragen te doen bij andere locaties zodat de kans groter is dat je een
                standplaats vindt!</p>
            </>
          }
          {value === 'spot-group-requests-stand' &&
            <>
              <p>De stand of truck heeft jouw aanvraag ontvangen. Je krijgt via ons een bericht wanneer de stand of
                Truck
                jouw aanvraag goedgekeurd heeft. Zorg ervoor dat je daarna direct betaald om de reservering helemaal
                compleet te maken!</p>
              <p>Je bent vrij om meerdere aanvragen te doen bij andere stand of trucks zodat de kans groter is dat je er
                een vindt</p>
            </>
          }
        </>
      }
      {booking.direction === 'receiving' &&
        <>
          <p><strong>Bedankt voor het accepteren van de aanvraag!</strong></p>
          <p>Booking ID: {booking.booking_number}</p>
        </>
      }

    </div>
  )
}

export default BookingFinish
