import React from "react";
import PropTypes from "prop-types";

const Select = (props) => {

  const {
    label = '',
    name = '',
    value = '',
    model = null,
    className = '',
    relation = '',
    relationLabel = '',
    hasError = false,
    onChange = () => {},
    error = '',
    isLoading
  } = props

  return (
    <div className={className + 'select '}>
      <label>{label}
        {hasError && <span className="error"> - {error}</span>}
        <div className="form-row">
          <select
            disabled={isLoading}
            name={name}
            value={value ? value : ''}
            onChange={onChange}
          >
            <option value=""> - - -</option>
            {model && relation in model && model[relation].map((item, i) => {
                return (
                  <option key={i} value={item.id}>{item[relationLabel]}</option>
                )
              }
            )}
          </select>
        </div>
      </label>
    </div>
  )
}


Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  model: PropTypes.object,
  className: PropTypes.string,
  relation: PropTypes.string,
  relationLabel: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
}
export default Select
