// @note needs to be synced with the backend function
// UserProgressStepEnum

const userProgressStepEnum = [
  { value: 'account_created', label: 'Account aangemaakt*' },
  { value: 'account_validated', label: 'Gevalideerd & ingelogd*' },
  { value: 'company_linked', label: 'Bedrijf aangemaakt*' },
  { value: 'profile_created', label: 'Profiel aangemaakt*' },
  { value: 'profile_bookable', label: 'Profiel boekbaar*' },
  // { value: 'shared_on_socials', label: 'Gedeeld op socials' },
  { value: 'booking_request_sent', label: 'B.verz. verstuurd' },
  { value: 'booking_request_received', label: 'B.verz. ontvangen' },
  { value: 'booking_accepted', label: 'Boeking "accepted"*' },
  { value: 'booking_paid', label: 'Boeking "paid"*' },
  { value: 'booking_completed', label: 'Boeking "completed"*' },
  // { value: 'review_given', label: 'Review gegeven' },
  // { value: 'ten_bookings_completed', label: '10x "completed"' },
];

export default userProgressStepEnum;