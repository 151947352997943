import React from "react";
import Fields from './Fields'
import Input from "./Input";
import Slug from "./Slug";
import Select from "./Select";
import MultiBlock from "./MultiBlock";
import Static from "./Static";
import SelectArray from "./SelectArray";
import Media from "./Media";
import SelectBlock from "./SelectBlock";
import Textarea from "./Textarea";
import Options from "./Options";
import AddMultiBlock from "./AddMultiBlock";
import Price from "./Price"
import DoubleInput from "./DoubleInput"
import Checkbox from "./Checkbox";
import Bool from "./Bool";
import Container from "./Container";
import "./style.scss"

const Form = (props) => {
  return (
    <div className="form-component">
      {props.children}
    </div>
  )
}

/**
 * This form component uses child components in 2 nested layers, like this:
 * <Form>
 *   <Fields>
 *     <Input
 *       label="The field label"
 *       name="the laravel model key"
 *     />
 *   </Fields>
 * </Form>
 *
 * The Index, Show, Create, Update, Delete components pass automatically the
 * value, error, and more props to the children.
 * label and name should be passed as props in JSX
 *
 *
 * Type of field components:
 * - Input (html prop 'type': email, text, etc)
 * - Select (select related/child model) (props: static, column/key)
 * - TextField
 * - Message ?
 * - StaticHasMany ?
 * - StaticHasOne
 * - SelectArray
 * - Password ?
 * - Media
 */


export {
  Form,
  Fields,
  Input,
  Options,
  Slug,
  Static,
  Select,
  MultiBlock,
  SelectArray,
  Media,
  SelectBlock,
  Textarea,
  AddMultiBlock,
  Price,
  DoubleInput,
  Checkbox,
  Bool,
  Container,
}
