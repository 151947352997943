import React from "react";
import Button from "../Button/Button";
import usePrice from "../../hooks/usePrice";
import useBookable from "../../hooks/useBookable";
import "./style.scss"

const Totals = (props) => {

  const {
    bookable,
    booking,
    handleClickMessage,
    handleClickReserve
  } = props

  const {
    countBookingPriceTypePositive,
    countBookingPriceTypeNegative,
    countBookingPriceTypeNegotiate,
    countBookingPriceTypeRelative,
    getBookingTotalPricePositive,
    getBookingTotalPriceNegative,
    getBookingTotalFormattedPercentagesRelative,
    formatPrice,
    addBookingFee,
    getBookingTotalAmountNegotiate,
    getBookingFeeAndTransactionFee,
    transactionFee,
    getBookingPriceTotalWithoutOptions,
    // getBookingPriceTotalWithoutFee,
    isPositivePrice,
  } = usePrice()

  const {
    isStand,
    isSpotGroup,
    isOwnedByCurrentActiveCompany,
    getEditLink,
  } = useBookable()

  const countTypePositive = countBookingPriceTypePositive(booking, bookable)
  const countTypeNegative = countBookingPriceTypeNegative(booking, bookable)
  const countTypeNegotiate = countBookingPriceTypeNegotiate(booking, bookable)
  const countTypeRelative = countBookingPriceTypeRelative(booking, bookable)
  const bookingPriceTotalWithoutOptions = getBookingPriceTotalWithoutOptions(booking, bookable)

  return (
    <div
      className="component-totals"
    >
      <div className="buttons">
        {isOwnedByCurrentActiveCompany(bookable) ?
          <>
            <p className="your-text">Deze {(isStand(bookable)) ? 'stand of truck' : 'locatie'} is van jou!</p>
            <Button
              label="Profiel Instellingen"
              link={getEditLink(bookable)}
              color="grey-ghost"
            />
            <Button
              label={(isStand(bookable)) ? 'Stand Management' : 'Locatie Management'}
              link={(isStand(bookable) ? '/user/stand/' + bookable.id + '/manager' : '/user/locatie/' + bookable.id + '/manager')}
              color="blue-ghost"
            />
          </>
          :
          <>
            <Button
              label="Stuur bericht"
              onClick={() => handleClickMessage()}
              color="grey-ghost"
            />
            <Button
              label="Reserveren"
              onClick={() => handleClickReserve()}
            />
          </>
        }

      </div>

      <div className="totals">
        {/*<div className="row discount">*/}
        {/*  <div className="col description">{booking.dates.length} <span>x</span> korting</div>*/}
        {/*  <div className="col price">&euro; 15,00</div>*/}
        {/*</div>*/}

        <div className="row days grand-totals">
          <div
            className="col description">{(booking.dates.length === 1) ? booking.dates.length + ' dag' : booking.dates.length + ' dagen'}</div>
          {/*<div className="col price"></div>*/}
        </div>
        <hr/>

        {isSpotGroup(bookable) &&
          <div className="row spots grand-totals">
            <div
              className="col description">{(booking.spots.length === 1) ? booking.spots.length + ' standplaats' : booking.spots.length + ' standplaatsen'}:
            </div>
            {/*<div className="col price"></div>*/}
          </div>
        }

        {!!countTypePositive &&
          <div className="row total">
            <div className="col description">
              {countTypePositive}, Totaal te betalen
            </div>
            <div className="col price">
              {formatPrice(addBookingFee(getBookingTotalPricePositive(booking, bookable)))}
            </div>
          </div>
        }

        {!!countTypeNegative &&
          <div className="row total">
            <div className="col description">
              {countTypeNegative}, Te ontvangen
            </div>
            <div className="col price">
              {formatPrice(getBookingTotalPriceNegative(booking, bookable))}
            </div>
          </div>
        }

        {isSpotGroup(bookable) && !!countTypeNegotiate &&
          <div className="row total">
            <div className="col description">
              {countTypeNegotiate}, standplaatsen n.o.t.k.
            </div>
            <div className="col price">
              {getBookingTotalAmountNegotiate(bookable, booking)}
            </div>
          </div>
        }

        {!!countTypeRelative &&
          <div className="row total">
            <div className="col description">
              {countTypeRelative}, Totaal op basis van omzet
            </div>
            <div className="col price">
              {getBookingTotalFormattedPercentagesRelative(booking, bookable)}
            </div>
          </div>
        }

        <hr/>

        <div className="row grand-totals">
          {(isPositivePrice(booking, bookable))
            ?
            <>
              <div className="col description"><a target="_blank" href="/prijzen-betaalmethodes/">Boekingskosten:</a>
              </div>
              <div
                className="col price">{formatPrice(transactionFee)}</div>
            </>
            :
            <>
              <div className="col description"><a target="_blank" href="/prijzen-betaalmethodes/">Boekingskosten &
                Fee:</a>
              </div>
              <div className="col price">{formatPrice(getBookingFeeAndTransactionFee(booking, bookable))}</div>
            </>
          }

        </div>
        <hr/>

        <div className="row grand-totals">
          <div className="col description">Totaal (ex
            BTW) {(bookingPriceTotalWithoutOptions < 0) ? 'te ontvangen' : 'te betalen'}</div>
          <div
            className="col price">
            {formatPrice(Math.abs(bookingPriceTotalWithoutOptions))}
          </div>
        </div>

      </div>
    </div>
  )
}


export default Totals
