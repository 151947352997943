import React from "react";
import PropTypes from "prop-types";
import useForm from "../../hooks/useForm";

const Textarea = (props) => {

  const {getValue} = useForm()

  const {
    label = '',
    name = '',
    value = '',
    className = '',
    hasError = false,
    onChange = () => {},
    error = '',
    isLoading,
    placeholder = '',
    rows = null,
  } = props

  return (
    <div className={className+'textarea '}>
      <label>{label}
        {hasError && <span className="error"> - {error}</span>}
        <textarea
          disabled={isLoading}
          name={name}
          value={getValue(value)}
          onChange={onChange}
          placeholder={placeholder}
          rows={(rows)?rows:5}
        />
      </label>
    </div>
  )
}

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
}

export default Textarea
