import React from "react";
import PropTypes from "prop-types";

const Container = (props) => {

  const {
    className = '',
  } = props

  return (
    <div className={className + 'container '}>
      {props.children}
    </div>
  )
}

Container.propTypes = {
  className: PropTypes.string,
}

export default Container
