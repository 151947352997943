import React from "react"
import {localhost} from "../../env";
import {Link} from "react-router-dom";
import useFunction from "../../hooks/useFunction";
import useBooking from "../../hooks/useBooking";
import arrowSimpleRight from "../../assets/icons/arrow-simple-right.svg";
import spotGroupIcon from "../../assets/icons/spot-group.svg";
import standIcon from "../../assets/icons/stand.svg";
import PropTypes from "prop-types";
import "./style.scss"

const BookingSmall = ({booking, showAction = true, target = '_self'}) => {
  const {getBookingStatusLabel, getBookingActionLabel} = useBooking()
  const {date} = useFunction()
  let className = " booking booking-status-" + booking.status
  className += (booking.needs_action) ? ' needs-action ' : ''

  return (
    <Link
      className="component-booking-small"
      to={'/user/boeking/' + booking.id}
      target={target}
    >
      <div className={className}>
        <div className="fields">
          <div className="field id">
            <p className={"label"}>ID:</p>
            <p>{booking.booking_number}</p>
            {localhost && <p>ID: {booking.id}</p>}
          </div>
          {showAction &&
            <div className="field status">
              <p className={"label"}>Actie</p>
              {(typeof booking.needs_action === 'string')
                ? <p><strong>Actie nodig: {getBookingActionLabel(booking.needs_action)}.</strong></p>
                : ''
              }
            </div>
          }
          <div className="field status">
            <p className={"label"}>Status:</p>
            <p>{getBookingStatusLabel(booking.status)}</p>
          </div>
          <div className="field direction">
            <p className={"label"}>Richting:</p>
            <p>{(booking.direction === 'receiving') ? 'Inkomend' : 'Uitgaand'}</p>
            {(booking.type === 'spot-group-requests-stand') &&
              <div className="row">
                <img width={22} src={spotGroupIcon} alt="Locatie"/>
                <img width={22} src={arrowSimpleRight} alt="->"/>
                <img width={22} src={standIcon} alt="Stand / truck"/>
              </div>
            }
            {(booking.type === 'stand-requests-spot') &&
              <div className="row">
                <img width={22} src={standIcon} alt="Stand / truck"/>
                <img width={22} src={arrowSimpleRight} alt="->"/>
                <img width={22} src={spotGroupIcon} alt="Locatie"/>
              </div>
            }
          </div>
          <div className="field dates">
            <p className={"label"}>Data: </p>
            {booking.date_ranges && !!booking.date_ranges.length && booking.date_ranges.map((dateRange, i) => {
              return (
                <div key={i}>
                  {dateRange.end_date ?
                    <p>{date(dateRange.start_date)} <span
                      className="meta">t/m</span> {date(dateRange.end_date)}</p>
                    :
                    <p>{date(dateRange.start_date)}</p>
                  }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Link>
  )
}

BookingSmall.propTypes = {
  booking: PropTypes.object.isRequired,
  showAction: PropTypes.bool,
  target: PropTypes.string,
}

export default BookingSmall
