import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthContext";
import spotGroupIconWhite from "../../assets/icons/spot-group-white.svg"
import standIconWhite from "../../assets/icons/stand-white.svg"
import spotGroupIcon from "../../assets/icons/spot-group.svg"
import messagesIcon from "../../assets/icons/message.svg"
import bookingIcon from "../../assets/icons/booking.svg"
import standIcon from "../../assets/icons/stand.svg"
import {useNavigate} from "react-router-dom";
import useUserService from "../../api/services/useUserService";
import BookingSmall from "../../components/BookingSmall/BookingSmall";
import useFunction from "../../hooks/useFunction";
import Loading from "../../components/Loading/Loading";
import Button from "../../components/Button/Button";
import Media from "../../components/Media/Media";
import Tile from "../../components/Tile/Tile";
import './style.scss'


const UserDashboard = () => {

  const {user} = useContext(AuthContext)
  const {userShowDashBoard} = useUserService()
  const [dashBoard, setDashBoard] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const {c} = useFunction()

  useEffect(() => {
    userShowDashBoard(
      (data) => {
        setDashBoard(data)
        c('dashBoard', data)
        setIsLoading(false)
      }
    )
  }, [])

  const Conversation = ({conversation, position}) => {
    return (
      <div className="row">
        {/*<div className="col meta">*/}
        {/*  {conversation.messages[conversation.messages.length - position].sent_by_user &&*/}
        {/*    <strong>{conversation.messages[conversation.messages.length - position].sent_by_user.first_name},&nbsp;</strong>*/}
        {/*  }*/}
        {/*  <span*/}
        {/*    className="date">{getDate(conversation.messages[conversation.messages.length - position])}</span>*/}
        {/*</div>*/}
        <div className="col content">
          {conversation.messages[conversation.messages.length - position].content.substring(0, 50)}...
        </div>
      </div>
    )
  }

  return (
    <div className="page-user-dashboard">
      <h1>Mijn Standplaats</h1>
      <p>Welkom {user && user.first_name} {user && user.last_name},
        van {user && user && user.active_company && user.active_company.legal_name}! Hier vind je al je een overzicht
        van de meest relevante informatie.
      </p>
      {isLoading ?
        <Loading/>
        :
        <>
          {!!dashBoard.conversations.length &&
            <div className="conversations">
              <h3><img src={messagesIcon} width={22}/>&nbsp;&nbsp;Laatste Berichten</h3>
              {dashBoard.conversations.map((conversation, i) => {
                return (
                  <div key={i}>
                    {i < 4 &&
                      <div
                        className="conversation"
                        key={i}
                        onClick={() => navigate('/user/berichten')}
                      >
                        <div className="conversation-button">
                          <div className="logo">
                            {'logo_media' in conversation.company &&
                              conversation.company.logo_media &&
                              <Media media={conversation.company.logo_media} size={'logo'}/>
                            }
                          </div>
                          <div className="name">{conversation.company.legal_name}</div>
                        </div>
                        <div className="latest_messages">
                          {conversation.messages.length >= 3 &&
                            <Conversation conversation={conversation} position={3}/>
                          }
                          {conversation.messages.length >= 2 &&
                            <Conversation conversation={conversation} position={2}/>
                          }
                          {conversation.messages.length >= 1 &&
                            <Conversation conversation={conversation} position={1}/>
                          }
                        </div>
                      </div>
                    }
                  </div>
                )
              })}
            </div>
          }

          {!!dashBoard.stands.length &&
            <div className="stands bookable">
              <div className="row">
                <div className="col title">
                  <h3><img src={standIcon} width={25}/>&nbsp;&nbsp;
                    {(dashBoard.stands.length > 1) ? 'Jouw stands & trucks' : 'Jouw stand of truck'}
                  </h3>
                </div>
                <div className="col button">
                  <Button
                    label="Nieuwe aanmaken"
                    icon={standIconWhite}
                    link="/wizard/stand/new"
                    linkState={{buttonPrev: {new: {onClick: {navigate: '/user/mijn-standplaats'}}}}}
                  />
                </div>
              </div>
              {!!dashBoard.stands.length ?
                <>
                  {dashBoard.stands.map((stand, i) => {
                    return (
                      <Tile
                        key={i}
                        bookable={stand}
                        showEditButton={true}
                      />
                    )
                  })}
                </>
                :
                <div className="no-results">Nog geen stands of trucks aangemaakt</div>
              }
            </div>
          }

          {!!dashBoard.spot_groups.length &&
            <div className="spot_groups bookable">
              <div className="row">
                <div className="col title">
                  <h3><img src={spotGroupIcon} width={23}/>&nbsp;&nbsp;
                    {(dashBoard.spot_groups.length > 1) ? 'Jouw locaties' : 'Jouw locatie'}
                  </h3>
                </div>
                <div className="col button">
                  <Button
                    label="Nieuwe aanmaken"
                    icon={spotGroupIconWhite}
                    link="/wizard/locatie/new"
                    linkState={{buttonPrev: {new: {onClick: {navigate: '/user/mijn-standplaats'}}}}}
                  />
                </div>
              </div>
              {!!dashBoard.spot_groups.length ?
                <>
                  {dashBoard.spot_groups.map((spotGroup, i) => {
                    return (
                      <Tile
                        key={i}
                        bookable={spotGroup}
                        showEditButton={true}
                      />
                    )
                  })}
                </>
                :
                <div className="no-results">Nog geen locaties aangemaakt</div>
              }
            </div>
          }

          <div className="bookings">
            {!!dashBoard.bookings.length &&
              <h3><img src={bookingIcon} width={22}/>&nbsp;&nbsp;Uitgaande en Binnenkomende&nbsp;
                {(dashBoard.bookings.length > 1) ? 'boekingen' : 'boeking'}
              </h3>
            }
            {!!dashBoard.bookings.length && dashBoard.bookings.map((booking, i) => {
              return (
                <BookingSmall
                  booking={booking}
                  key={i}
                />
              )
            })}
          </div>
        </>
      }
    </div>
  )
}

export default UserDashboard
