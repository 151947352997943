import React from "react";
import spotGroupIcon from "../../assets/icons/spot-group-white.svg";
import useSpotGroupService from "../../api/services/useSpotGroupService";
import Button from "../../components/Button/Button";
import TileList from "../../components/TileList/TileList";


const UserSpotGroups = () => {

  const {userShowCollection} = useSpotGroupService()

  return (
    <div>
      <h1>Locaties</h1>
      <p>Locaties zijn: markten, evenementen, kantoor tereinen, winkelcentra's, braderien, festivals. Een locatie heeft
        een of meerdere standplaatsen die een stand/mobiele ondernemer kan boeken en gebruiken om zijn
        goederen te verkopen!</p>
      <Button
        label="Locatie aanmaken"
        linkState={{buttonPrev:{new:{onClick:{navigate:'/user/locaties'}}}}}
        link="/wizard/locatie/new"
        icon={spotGroupIcon}
      />
      <br/>
      <br/>
      <br/>
      <TileList
        apiServiceShow={userShowCollection}
        modelLabelKey="label"
        modelImageKey="overview_media"
        showBookableStatus={true}
        buttonProfileBaseUrl={'/locatie/'}
        buttonOneDynamicUrl={'/user/locatie/%id/manager'}
        buttonOneLabel={'Management'}
        buttonOneColor={'blue'}
        buttonTwoDynamicUrl={'/user/locatie/%id/edit'}
        buttonTwoLabel={'Instellingen'}
        buttonThreeDynamicUrl={'/user/locatie/%id/spots'}
        buttonThreeLabel={'Standplaats instel.'}
      />
    </div>
  )
}

export default UserSpotGroups
