import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Info from "../Info/Info";

const DoubleInput = (props) => {

  const {
    label = '',
    name = '',
    value = '',
    className = '',
    hasError = false,
    onChange = () => {},
    error = '',
    isLoading,
    formData,
    setFormData,
    removeErrors,
    type = 'text',
    text = '',
    autoFocus = '',
  } = props

  const [repeatInput, setRepeatInput] = useState('')
  const [differenceError, setDifferenceError] = useState(false)

  useEffect(() => {
    if (value !== repeatInput){
      setDifferenceError('Niet hetzelfde!')
      setFormData(prevFormData => {
        return {
          ...prevFormData,
          isLocked: true,
        }
      })
    } else {
      setDifferenceError(false)
      setFormData(prevFormData => {
        return {
          ...prevFormData,
          isLocked: false,
        }
      })
    }
  }, [value, repeatInput])


  const handelChangeRepeatInput = (e) => {
    e.preventDefault()
    setRepeatInput(e.target.value)
    formData.model = removeErrors(formData.model, name)
    setFormData(prevFormData => {
      return {
        ...prevFormData,
        model: formData.model,
        isChanged: true,
        isStored: false,
      }
    })
  }

  const getValue = () => {
    return (!isLoading && value && value !== '') ? value : ''
  }

  const getClassName = () => {
    let newClassName = className+ 'double-input '
    if (differenceError){
      return newClassName+' has-error'
    }
    return newClassName
  }

  return (
    <div className={getClassName()}>
      <label>{label} <Info
        content={text}
      />
        {hasError && <span className="error"> - {error}</span>}
        <div className="form-row">
          <input
            disabled={isLoading}
            name={name}
            value={getValue()}
            onChange={onChange}
            type={type}
            autoFocus={autoFocus}
          />
        </div>
      </label>
      <label>Herhaal {label}
        {differenceError && <span className="error"> - {differenceError}</span>}
        <div className="form-row">
          <input
            disabled={isLoading}
            name={name+"_repeat"}
            value={repeatInput}
            onChange={handelChangeRepeatInput}
            type={type}
          />
        </div>
      </label>
    </div>
  )
}

DoubleInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  autoFocus: PropTypes.string,
}

export default DoubleInput
