import React, {useContext, useEffect, useState} from "react";
import Moment from 'moment';
import AuthContext from "../../context/AuthContext";
import {useAppContext} from "../../context/AppContextProvider";
import {Link, useParams} from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useMessengerService from "../../api/services/useMessageService";
import useBookingService from "../../api/services/useBookingService";
import useLocationState from "../../hooks/useLocationState";
import BookingActions from "../BookingActions/BookingActions";
import useFunction from "../../hooks/useFunction";
import useInterval from "../../hooks/useInterval";
import useBooking from "../../hooks/useBooking";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import Media from "../Media/Media";
import swal from "sweetalert";
import spotGroupIcon from "../../assets/icons/spot-group.svg";
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from "prop-types";
import standIcon from "../../assets/icons/stand.svg";
import backIcon from "../../assets/icons/arrow-back-white.svg";
import './style.scss'
import useQueryString from "../../hooks/useQueryString";

const Messages = (props) => {
  const {fixedConversation = null} = props
  const refreshMs = 3000;
  const {conversationId} = useParams()
  const {c} = useFunction()
  const {location, hasLocationState} = useLocationState()
  const {userShow, userSend, userRead} = useMessengerService()
  const {objIsEmpty, truncate, isSmallScreen} = useFunction()
  // const {state: locationState} = useLocation();
  const [activeConverseId, setActiveConverseId] = useState(fixedConversation)
  const [latestId, setLatestId] = useState(0)
  const {user} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const {notifications} = useAppContext()
  const [oldNotifications, setOldNotifications] = useState(cloneDeep(notifications))
  const [showMessagesOnly, setShowMessagesOnly] = useState(false)
  const {width} = useWindowDimensions();
  const initMessages = {
    conversations: [],
    lastMessageId: 0,
  }
  const [messages, setMessages] = useState(initMessages)
  const [input, setInput] = useState({
    conversations: {},
  })
  const {userAction} = useBookingService()
  const {getBookingStatusLabel, getBookingActionLabel} = useBooking()
  const {getUrl} = useQueryString()

  //hide website footer when this component is loaded
  useEffect(() => {
    const footer = document.querySelector('#footer.site-footer')
    if (footer) {
      footer.classList.add('hide-footer-on-mob')
    }
    return () => {
      if (footer) {
        footer.classList.remove('hide-footer-on-mob')
      }
    }
  }, []);

  /**
   * Do the API Show call
   */
  useEffect(() => {
    if (objIsEmpty(user)) {
      setIsLoading(true)
    } else {
      getMessages(latestId, (data) => {
        setIsLoading(false)
      })
    }
  }, [])

  /**
   * Get user when user-object changes
   */
  useEffect(() => {
    setIsLoading(true)
    if (!objIsEmpty(user)) {
      getMessages(latestId, (data) => {
        setIsLoading(false)
      })
    }
  }, [user]);

  /**
   *
   */
  useEffect(() => {
    if (!isLoading) {
      scrollMessageContainer('instant')
      if (conversationId) {
        setActiveConverseId(parseInt(conversationId))
      } else if (fixedConversation) {
        setActiveConverseId(parseInt(activeConverseId))
      } else if (messages.conversations.length) {
        //todo something wrong here

        setActiveConverseId(messages.conversations[0].company.id)
      }
    }
  }, [isLoading])

  /**
   *
   */
  useInterval(() => {
    if (notifications !== oldNotifications) {
      getMessages(latestId)
      setOldNotifications(cloneDeep(notifications))
    }
  }, refreshMs);

  /**
   *
   */
  useEffect(() => {
    setMessages(prevMessages => {
      return {
        ...prevMessages,
        conversations: sortLatest(messages.conversations),
      }
    })
  }, [latestId])


  /**
   * Slow scroll when there is a new message
   *
   * //todo maybe only scroll when the container is already (or almost) at bottom
   *
   */
  useEffect(() => {
    scrollMessageContainer()
  }, [latestId])

  /**
   * fast scroll when the conversation changes
   */
  useEffect(() => {
    scrollMessageContainer('instant')
    if (activeConverseId && !objIsEmpty(user)) {
      userRead(
        () => {
        },
        () => {
        },
        {conversation_id: activeConverseId}
      )
    }
  }, [activeConverseId])

  /**
   * add conversation when the locationState is set with a company
   */
  useEffect(() => {
    if (hasLocationState('company') && conversationId) {
      addConversation(location.state.company)

      /* sets the active company to the one from location state.
      but is a bit dirty like this, because the conversation might
      not have been added yet*/
      setActiveConverseId(conversationId)
    }
  }, [location.state])

  useEffect(() => {
    scrollMessageContainer()
  }, [messages])

  /**
   *
   */
  const getMessages = (latestId, callbackSuccess) => {

    if (objIsEmpty(user)) {
      return
    }

    userShow(
      (data) => {
        setMessages(prevMessages => {
          return {
            ...prevMessages,
            conversations: mergeConversations(messages.conversations, data.conversations),
          }
        })
        setLatestId(data.latest_message_id)
        if (callbackSuccess) {
          callbackSuccess(data)
        }
      },
      (error) => {

      },
      latestId
    )
  }

  const getCompanyInfo = () => {
    const conversation = getConversation(activeConverseId)
    if (!conversation) {
      return
    }
    const company = conversation.company
    return (
      <div className="company-info">
        <strong>{conversation.company.legal_name}</strong>&nbsp;
        {!!company.stands && company.stands.map((stand, i) => {
          if (!stand.available || !stand.slug) {
            return <></>
          }
          return (<>stand:&nbsp;
            <Link
              to={'/stand/' + stand.slug}
              target={"_blank"}
            >{stand.label}</Link>
            &nbsp;</>)
        })}
        {!!company.spot_groups && company.spot_groups.map((spotGroup, i) => {
          if (!spotGroup.available || !spotGroup.slug) {
            return <></>
          }
          return (<>locatie:&nbsp;
            <Link
              to={'/locatie/' + spotGroup.slug}
              target={"_blank"}
            >{spotGroup.label}</Link>
            &nbsp;</>)
        })}
      </div>
    )
  }

  /**
   * Merge two conversation objects
   *
   * @param conversationsA
   * @param conversationsB
   * @returns {*}
   */
  const mergeConversations = (conversationsA, conversationsB) => {
    conversationsA.map((a) => {
      let hitC = false
      conversationsB.map((b, indexB) => {
        if (a.company.id === b.company.id) {

          /* conversations are in both 'a' and 'b', so merge messages */
          hitC = true

          const ids = new Set(a.messages.map(message => message.id));
          conversationsB[indexB].messages = [...a.messages, ...b.messages.filter(d => !ids.has(d.id))];
        }
        return b
      })

      if (!hitC) {
        /* conversation in 'a' does not exist in 'b', so add */
        conversationsB.unshift(a)
      }
      return a
    })

    /* Sort by latest message */
    return sortLatest(conversationsB)
  }

  /**
   * Sort by conversations by latest message
   *
   * @param conversations
   * @returns {*}
   */
  const sortLatest = (conversations) => {
    return conversations.sort((a, b) => ('latest_message' in b && 'latest_message' in a && a.latest_message.id < b.latest_message.id) ? 1 : -1)
  }

  /**
   *
   *
   * @param company
   */
  const addConversation = (company) => {
    messages.conversations.push({
      company: company,
      messages: []
    })
    setMessages(prevState => {
      return {
        ...prevState,
        conversations: messages.conversations
      }
    })
  }

  useEffect(() => {
    setShowMessagesOnly(isSmallScreenHide())
  }, [width])

  /**
   *
   * @param i
   */
  const handleConversationClick = (i) => {
    if (isSmallScreenHide) {
      setShowMessagesOnly(true)
    }
    setActiveConverseId(messages.conversations[i].company.id)
    // scrollMessageContainer() //todo maybe need this?
  }

  const isSmallScreenHide = () => {
    return (isSmallScreen(width, 900)) //note 900 is also set in corresponding style.css
  }

  const handleConversationBackClick = () => {
    setShowMessagesOnly(false)
  }

  /**
   *
   * @param e
   * @param converseId
   */
  const handleChangeInput = (e, converseId) => {
    input.conversations[converseId] = {
      value: e.target.value,
      isChanged: true,
    }
    setInput(prevInput => {
      return {
        ...prevInput,
        conversations: input.conversations,
      }
    })
  }

  /**
   * Scroll the div message container to bottom
   */
  const scrollMessageContainer = (behavior = 'smooth') => {
    const messageContainer = document.getElementsByClassName('messages-container')[0]  //todo use useRef: const inputRef = useRef(null);  ref={inputRef};  access via     console.log('inputRef', inputRef.current);
    if (messageContainer) {
      messageContainer.scrollTo({
        top: messageContainer.scrollHeight,
        left: 0,
        behavior: behavior
      },)
    }
  }

  /**
   * //todo this maybe can be cheaper. now this method is called a lot. Maybe set this in a state?
   *
   *
   * @param converseId
   * @returns {*}
   */
  const getConversation = (converseId) => {
    if (messages.conversations && typeof messages.conversations === 'object') {
      return messages.conversations.find(conversation => parseInt(conversation.company.id) === parseInt(converseId))
    }
  }

  /**
   * //todo this maybe can be cheaper. now this method is called a lot. Maybe set this in a state?
   *
   *
   * @param converseId
   * @returns {*}
   */
  const getConversationIndex = (converseId) => {
    if (messages.conversations && typeof messages.conversations === 'object') {
      return messages.conversations.findIndex(conversation => parseInt(conversation.company.id) === parseInt(converseId))
    }
  }

  /**
   * Handle send message click event
   *
   * @param converseId
   */
  const handleSend = (converseId) => {
    setIsSending(true)

    /* add temporary added 'new' message */
    const newConversation = messages.conversations[getConversationIndex(converseId)]
    if (newConversation) {
      newConversation.messages.push({
        content: input.conversations[converseId].value,
        sent_by_company: user.active_company,
        sent_by_company_id: user.active_company.id,
        received_by_company: newConversation.company,
        received_by_company_id: newConversation.company.id,
        sent_by_user: user,
        sent_by_user_id: user.id,
        read: 0,
        created_at: false,
      })
      setMessages({...messages, conversations: sortLatest(messages.conversations)})
    }

    userSend(
      (data) => {

        input.conversations[converseId] = {
          value: '',
        }
        setInput(prevInput => {
          return {
            ...prevInput,
            conversations: input.conversations,
          }
        })

        if (newConversation) {
          /* remove temporary added message */
          newConversation.messages.pop()
        }

        setIsSending(false)
        setMessages(prevMessages => {
          return {
            ...prevMessages,
            conversations: mergeConversations(data.conversations, messages.conversations),
          }
        })
        setLatestId(data.latest_message_id)
      },
      (errors) => {
        c('validation errors', errors)
        messages.conversations.map(conversation => {
          const index = conversation.messages.findIndex(message => !('id' in message))
          conversation.messages.splice(index, 1)
          return conversation
        })
        swal({
          title: 'Error: ' + errors.content[0],
          icon: 'warning',
          timer: 2000,
          buttons: false,
        })
        setMessages(messages)
        setIsSending(false)
      },
      {
        content: input.conversations[converseId].value,
        company_id: converseId
      }
    )
  }

  /**
   * get a string conversation input value
   *
   * @param converseId
   * @return {string}
   */
  const getValue = (converseId) => {
    if (
      converseId in input.conversations &&
      'value' in input.conversations[converseId]
    ) {
      return input.conversations[converseId].value
    } else {
      return ''
    }
  }

  /**
   * Return bool if conversation input has changed
   *
   * @param converseId
   * @return {boolean}
   */
  const getIsChanged = (converseId) => {
    return converseId in input.conversations &&
      'isChanged' in input.conversations[converseId];
  }

  /**
   *  //todo can be in useFunctions()
   * @param message
   * @returns {string}
   */
  const getDate = (message) => {
    if (message.created_at) {
      const date = new Date(message.created_at)
      return Moment(date).format('H:mm:ss, D MMMM YYYY'); // August 20th 2015, 5:09:08 pm
    } else {
      return 'versturen...'
    }
  }

  /**
   *
   * @param conversation
   * @param unreadCount
   * @returns {string|string}
   */
  const getClassName = (conversation, unreadCount) => {
    let className = (unreadCount === 0) ? ' conversation-button ' : ' conversation-button unread ';
    return (activeConverseId === conversation.company.id) ? className + ' active' : className
  }

  /**
   *
   * @param message
   * @returns {string}
   */
  const getDirection = (message) => {
    if (message.sent_by_company_id === user.active_company_id) {
      return 'sent'
    } else {
      return 'received'
    }
  }

  /**
   *
   * @param conversation
   * @returns {number}
   */
  const countUnreadMessages = (conversation) => {
    let count = 0
    conversation.messages.map((message) => {
      if (message.read === 0) {
        count++
      }
      return message
    })
    return count
  }

  /**
   *
   * @param message
   * @returns {JSX.Element}
   */
  const messageByType = (message, i) => {
    switch (message.type) {

      //todo check for all MessageTypeEnums, and add enum in front-end: 'booking', 'system', 'ticket', 'alert'

      case 'system':
        return (
          <div className="content system">
            <div className="row">
              <div className="col">
                <div className="date">{getDate(message)}</div>
              </div>
            </div>
            <div className="text">{message.content}</div>
          </div>
        )
      case 'company-attache-user':
        return (
          <div className="content">
            <div className="row">
              <div className="col">
                <div className="date">{getDate(message)}</div>
              </div>
            </div>
            <div className="text">{message.content}</div>
          </div>
        )
      case 'booking':
        if (
          message.booking.status === 'paid' ||
          message.booking.status === 'requested' ||
          message.booking.status === 'accepted'
        ) {
          return (
            <div className={"content booking " + message.type}>
              <div className="row">
                <div className="col">
                  <div className="sender-name">Boeking
                    id: {message.booking.booking_number} Huidige
                    status: {getBookingStatusLabel(message.booking.status)}</div>
                </div>
                <div className="col">
                  <div className="date">{getDate(message)}</div>
                </div>
              </div>
              <div className="text">
                {/*<div className="row">{message.content}</div>*/}
                <div className="row">
                  {/*<div className="col">Status: {getBookingStatusLabel(message.booking.status)}</div>*/}
                  <div className="col">
                    {message.content} -
                    {message.booking.needs_action ? getBookingActionLabel(message.booking.needs_action) : ''}
                  </div>
                </div>
                <div className="row">
                  <div className="buttons">

                    {message.booking.spot_group &&
                      <>
                        <span className="label">Locatie:</span>
                        <Button
                          color="grey-ghost"
                          size="xs"
                          link={'/locatie/' + message.booking.spot_group.slug}
                          label={truncate(message.booking.spot_group.label, 20)}
                          target="_blank"
                          icon={spotGroupIcon}
                        />
                      </>
                    }
                    <span className="label">Stand:</span>
                    <Button
                      color="grey-ghost"
                      size="xs"
                      link={'/stand/' + message.booking.stand.slug}
                      label={truncate(message.booking.stand.label, 20)}
                      target="_blank"
                      icon={standIcon}
                    />


                    <Button
                      label="Meer info"
                      size="xs"
                      color="grey"
                      link={"/user/boeking/" + message.booking.id}
                    />

                    <BookingActions
                      buttonSize="xs"
                      booking={message.booking}
                      callbackConfirm={(booking, action, denyReason) => {
                        setIsLoading(true)
                        userAction(
                          (data) => {

                            //todo old buttons looks like are not updating??
                            setMessages(initMessages)
                            getMessages(latestId, (data) => {
                              setIsLoading(false)
                            })

                            /*
                             * Action remove
                             */
                            if (action === 'remove') {
                              // const objWithIdIndex = bookings.findIndex((obj) => obj.id === booking.id);
                              // if (objWithIdIndex > -1) {
                              //   bookings.splice(objWithIdIndex, 1);
                              // }
                              // setBookings(bookings)
                            } else {

                              /*
                               * Action cancel-request
                               */
                            }
                            setIsLoading(false)
                          },
                          (error) => {
                            c('booking update error', error)
                          },
                          booking.id,
                          {action: action, deny_reason: denyReason},
                        )
                      }}
                    />


                    {/*<Button*/}
                    {/*  size="xs"*/}
                    {/*  link={'/wizard/boek/start/' + message.booking.id}*/}
                    {/*  label="Bewerken"*/}
                    {/*  linkState={getWizardBookingState()}*/}
                    {/*/>*/}


                    <br/>
                    {
                      message.booking.status === 'accepted' &&
                      message.booking.payments.length > 0 &&
                      <>
                        {message.booking.payments.map((payment, i) => {
                          return (
                            <>
                              <p>Betaal direct om de boeking definitief te maken:</p>
                              <Button
                                label="Betalen"
                                linkOutside={payment.payment_url}
                              />
                            </>
                          )
                        })}
                      </>
                    }
                  </div>
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          )
        }
        if (
          message.booking.status === 'rejected' ||
          message.booking.status === 'canceled' ||
          message.booking.status === ''
        ) {
          return (
            <div className={"content booking " + message.type}>
              <div className="row">
                <div className="col">
                  <div className="sender-name">Status Update:</div>
                </div>
                <div className="col">
                  <div className="date">{getDate(message)}</div>
                </div>
              </div>
              <div className="text">
                <div className="row">
                  {/*<div className="col">Status: {getBookingStatusLabel(message.booking.status)}</div>*/}
                  <div className="col">
                    {message.content}.
                    {message.booking.needs_action ? getBookingActionLabel(message.booking.needs_action) : ''}
                  </div>
                </div>
              </div>
            </div>
          )
        }
        break;
      case 'alert':
        return (
          <div className="content alert">
            <div className="row">
              <div className="col">
                <div
                  className="sender-name">Alert:
                </div>
              </div>
              <div className="col">
                <div className="date">{getDate(message)}</div>
              </div>
            </div>
            <div className="text">{message.content}</div>
            {message.data &&
              <div className="row">
                <Button
                  label="Resultaten"
                  link={'/?' + getUrl(message.data.filter)}
                  size="s"
                  color="blue"
                />
              </div>
            }
          </div>
        )
      default:
        return (
          <div className="content">
            <div className="row">
              <div className="col">
                <div
                  className="sender-name">{(message.sent_by_user) ? message.sent_by_user.first_name : ''}:
                </div>
              </div>
              <div className="col">
                <div className="date">{getDate(message)}</div>
              </div>
            </div>
            <div className="text">{message.content}</div>
          </div>
        )
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend(activeConverseId)
    }
  };

  return (
    <div className="component-messages">

      {isLoading ?
        <Loading
          loadingIconFixed={false}
        />
        :
        <>
          <div className="container">
            {((!isSmallScreenHide() && !fixedConversation) || (isSmallScreenHide() && !fixedConversation && !showMessagesOnly)) &&
              <div className="conversations">
                {
                  messages.conversations &&
                  typeof messages.conversations === 'object' &&
                  // messages.conversations.isArray &&
                  messages.conversations.map((conversation, i) => {
                    const count = countUnreadMessages(conversation)
                    return (
                      <div
                        key={i}
                        className={getClassName(conversation, count)}
                        onClick={() => handleConversationClick(i)}
                      >
                        {count !== 0 &&
                          <div className="count">{count}</div>
                        }
                        <div className="logo">
                          {'logo_media' in conversation.company &&
                            conversation.company.logo_media &&
                            <Media media={conversation.company.logo_media} size={'logo'}/>
                          }
                        </div>
                        <div className="name">{conversation.company.legal_name}</div>
                      </div>
                    )
                  })
                }
              </div>
            }
            {(!isSmallScreenHide() || (isSmallScreenHide() && showMessagesOnly)) &&
              <div className="messages">

                {isSmallScreenHide() &&
                  showMessagesOnly &&
                  !fixedConversation &&
                  <Button
                    label="Conversaties"
                    onClick={handleConversationBackClick}
                    icon={backIcon}
                  />
                }

                {!fixedConversation &&
                  <div className="company-details">
                    {getCompanyInfo()}
                  </div>
                }

                <div className="messages-container">
                  {getConversation(activeConverseId) &&
                    getConversation(activeConverseId).messages.map((message, i) => {
                      return (
                        <div
                          key={i}
                          className={'message ' + getDirection(message) + ' ' + message.type}
                        >{messageByType(message, i)}
                        </div>
                      )
                    })
                  }
                </div>
                <div className="text-input-container">
                  <textarea
                    onChange={(e) => handleChangeInput(e, activeConverseId)}
                    value={getValue(activeConverseId)}
                    disabled={isSending}
                    onKeyDown={handleKeyDown}
                    autoFocus="autofocus"
                    placeholder="Type je bericht hier..."
                  />
                  <Button
                    disabled={!getIsChanged(activeConverseId) || messages.isLoading || isSending}
                    label="Versturen"
                    size={(fixedConversation) ? "m" : "l"}
                    onClick={() => handleSend(activeConverseId)}
                  />
                </div>
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}

Messages.propTypes = {
  fixedConversation: PropTypes.number,
}

export default Messages
