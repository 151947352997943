import React from "react";
import initUser from "../../data/initUser";
import {DoubleInput, Fields, Form, Input} from "../Form/Form";
import {useNavigate} from "react-router-dom";
import useAuthService from "../../api/services/useAuthService";
import useForm from "../../hooks/useForm";
import Button from "../Button/Button";
import PropTypes from "prop-types";
import "./style.scss"
import facebookIcon from "../../assets/icons/facebook.svg";
import googleIcon from "../../assets/icons/google.svg";
import linkedIn from "../../assets/icons/linkedIn.svg";

const Register = (props) => {

  const {showLoginButton = true} =  props
  const {register} = useAuthService()
  const form = useForm()
  const navigate = useNavigate();

  const updateSuccess = () => {
    navigate('/email/verification')
  }

  return (
    <div className="component-register">
      <h3>Maak een gebruikersaccount aan:</h3>

      <div className="social-login-buttons">
        <Button
          label="Facebook Login"
          icon={facebookIcon}
          color="grey-ghost"
          size="s"
          link="https://backend.standplaats.nl/api/login_provider/facebook"
        />
        <Button
          label="Google Login"
          icon={googleIcon}
          color="grey-ghost"
          size="s"
          link="https://backend.standplaats.nl/api/login_provider/google"
        />
        <Button
          label="LinkedIn Login"
          icon={linkedIn}
          color="grey-ghost"
          size="s"
          link="https://backend.standplaats.nl/api/login_provider/linkedin-openid"
        />
      </div>

      <Form>
        <Fields
          form={form}
          initData={initUser}
          apiService={{userStore: register}}
          updateSuccess={updateSuccess}
          buttonConfirmLabel="Registreren"
          buttonCancelLabel="Annuleren"
          fixedFormButtons={false}
          GAEventLabel="Register"
        >
          <hr/>
          <Input
            label="Voornaam"
            name="first_name"
            // autoFocus="autofocus"
          />
          <Input
            label="Achternaam"
            name="last_name"
          />
          <Input
            label="Email"
            name="email"
          />
          <DoubleInput
            label="Wachtwoord"
            text="Gebruik minimaal: 1 speciaal/lees teken, 2 cijfers"
            name="password"
            type="password"
          />
          <p>Door in te loggen of een account aan te maken ga je akkoord met
            onze <a href="/algemene-voorwaarden/"> Algemene Voorwaarden</a> en <a
              href="/privacy-policy">Privacyverklaring</a>
          </p>
        </Fields>
      </Form>
      <br/>
      <br/>
      <br/>
      {showLoginButton &&
        <div className="login">
          <p>Heb je al een gebruikersaccount?</p>
          <Button
            link="/inloggen"
            label="Inloggen"
          />
        </div>
      }
    </div>
  )
}

Register.prototype = {
  showLoginButton: PropTypes.bool,
}

export default Register
