import React from "react";
import Button from "../Button/Button";
import UserButton from "../UserButton/UserButton";
import LogoContainer from "../LogoContainer/LogoContainer";
import spotGroupIcon from "../../assets/icons/spot-group.svg"
import helpIcon from "../../assets/icons/help.svg"
import "./style.scss"

const Header = () => {

  return (
    <div className="component-header">
      <div className="section-container">
        <div className="header-container">
          <div className="header-row">
            <div className="header-left">
              <LogoContainer/>
              <Button
                label="Hoe werkt het?"
                link="/hoe-werkt-het/"
                color="transparent"
                icon={helpIcon}
              />
            </div>
            <div className="header-right">
              <Button
                label="Verhuur locatie"
                linkOutside="/stand-truck-huren/locatie-verhuren/"
                color="transparent"
                icon={spotGroupIcon}
              />
              <UserButton/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
