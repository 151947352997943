import React from "react";
import Button from "../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";
import market from '../../assets/images/centrum-markt-2-comp.jpg'
import event from '../../assets/images/food-truck-evenement-comp.jpg'
import publicRoad from '../../assets/images/viskraam-standplaats-comp.jpg'
import fruitMarket from '../../assets/images/fruit-markt-comp.jpg'
import standIconWhite from "../../assets/icons/stand-white.svg"
import standIcon from "../../assets/icons/stand.svg"
import standplaatsIcon from "../../assets/icons/favicon.svg"
import spotGroupIconWhite from "../../assets/icons/spot-group-white.svg"
import spotGroupIcon from "../../assets/icons/spot-group.svg"
import standVsSpotGroup from "../../assets/icons/stand-vs-spot-group.svg"
import rentLocation from "../../assets/images/verhuur-locatie-standplaats-comp.webp";
import coffeeBarista from "../../assets/images/koffie-barista-comp.jpg";
import coffeeIcon from "../../assets/icons/coffee-white.svg";
import './style.scss'

const HowItWorks = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const handleClickMarked = (e) => {
    e.preventDefault()
    navigate('/?search_model=spot_group&types=1')
  }

  const handleClickEvent = (e) => {
    e.preventDefault()
    navigate('/?search_model=spot_group&types=3')
  }

  const handleClickPublic = (e) => {
    e.preventDefault()
    navigate('/?search_model=spot_group&types=32')
  }

  return (
    <div className="page-welcome">

      <div className="banner">
        <div className="banner-inner">
          <div className="center-container">
            <div className="center-inner">
              {/*<div className="button-container">*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>

      <section className="info">
        <div className="section-container">
          <h1 className="center">Op Standplaats.nl brengen we de stands & trucks en de locaties bij elkaar,</h1>
          <h1 className="center">zo kan iedereen makkelijk vinden wat ze zoeken!</h1>
          <br/>
          <br/>
          <br/>
          <h4>Stands & trucks, locaties en standplaatsen:</h4>
          <div className="row">
            <div className="col">
              <h2><img width={23} src={standIcon} alt="Stand of truck"/> stands, trucks & Acts:</h2>
              <p><strong>Stands, trucks & acts:</strong> Dit zijn de mensen & ondernemers met kramen, standhouders, foodtrucks en
                mobiele handelaren en ambulante handelaren. Ze zoeken plekken waar ze hun spullen of diensten kunnen verkopen.
              </p>
              <h4>Stand of truck eigenaren kunnen op Standplaats.nl:</h4>
              <ul>
                <li>Een openbaar profiel aanmaken zodat personen, locaties, markten, evenementen, etc jou kunnen vinden
                  en boeken.
                </li>
                <li>Of, zelf actief zoeken naar voor jou geschikte locaties en deze boeken.</li>
              </ul>
            </div>
            <div className="col">
              <h2><img width={23} src={spotGroupIcon} alt="locatie"/> Locaties:</h2>
              <p><strong>Locaties</strong> zijn de plekken waar de stands & trucks naartoe willen. Dit kan een markt
                zijn, een festival, een winkelcentrum, of zelfs een bedrijventerrein.</p>
              <h2><img width={23} src={standplaatsIcon} alt="Standplaats"/> standplaatsen:</h2>
              <p>Elke locatie heeft één of meerdere plekken die ze kunnen verhuren. We noemen die
                plekken <strong>standplaatsen</strong>.
                Een standplaats is een plek waar een stand of truck hun spullen kan verkopen.</p>
              <br/>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
        </div>
      </section>

      <section className="stand-spot-group">
        <div className="section-container">
          {/*<h1>Verhuur jouw <img width={25} src={spotGroupIcon} alt="locatie"/> locatie & standplaatsen</h1>*/}
          <div className="content">
            <div className="row main-image">
              <img src={standVsSpotGroup} alt="Stand / Truck vs locatie"/>
            </div>
            <div className="row below-deck">
              <div className="col">
                <p>Foodtrucks</p>
                <p>Marktkramen</p>
                <p>Mobiele ondernemers</p>
                <p>Acts</p>
                <p>Ambulante handelaren</p>
                <div className="row" style={{gap: 12}}>
                  <Button
                    label="Verhuur"
                    icon={standIconWhite}
                    color="grey"
                    linkOutside="/standplaats-huren/zoek-een-standplaats/"
                  />
                  <Button
                    label="Zoek stands & trucks"
                    icon={standIconWhite}
                    link="/?direction=search&search_model=stand"
                  />
                </div>
              </div>
              <div className="col col-smaller"></div>
              <div className="col">
                <p>Markten</p>
                <p>Evenementen</p>
                <p>Winkels</p>
                <p>Bedrijventerreinen</p>
                <p>Gemeentes (<a href="/organisaties/gemeenten-marktmeesters/">meer info</a>)</p>
                <div className="row" style={{gap: 12}}>
                  <Button
                    label="Verhuur"
                    color="grey"
                    icon={spotGroupIconWhite}
                    linkOutside="/stand-truck-huren/locatie-verhuren/"
                  />
                  <Button
                    label="Zoek locaties & standpl."
                    icon={spotGroupIconWhite}
                    link="/?direction=search&search_model=spot_group"
                  />
                </div>
              </div>
            </div>

            <div className="row bottom">
              <Button
                label="Verhuur"
                icon={standIconWhite}
                color="grey"
                size="s"
                linkOutside="/standplaats-huren/zoek-een-standplaats/"
              />
              <Button
                label="Zoek stands & trucks"
                icon={standIconWhite}
                size="s"
                link="/?direction=search&search_model=stand"
              />

              <Button
                label="Verhuur"
                color="grey"
                size="s"
                icon={spotGroupIconWhite}
                linkOutside="/stand-truck-huren/locatie-verhuren/"
              />
              <Button
                label="Zoek locaties & standpl."
                size="s"
                icon={spotGroupIconWhite}
                link="/?direction=search&search_model=spot_group"
              />

            </div>
          </div>
        </div>
      </section>

      <section className="why-rent">
        <div className="section-container">
          <h1>Verhuur jouw <img width={25} src={spotGroupIcon} alt="locatie"/> locatie & standplaatsen via
            Standplaats.nl</h1>
          <div className="content">
            <div className="row">
              <div className="col text">
                {/*<h3>Jouw locatie op Standplaats.nl</h3>*/}
                <ul>
                  <li>Maximale bezetting gegarandeerd</li>
                  <li>Aanmelden is snel en eenvoudig</li>
                  <li>Volledige controle over reserveringen</li>
                  <li>Gemakkelijk en snel</li>
                  <li>Groot bereik</li>
                  <li>Betaal alleen als je de boeking accepteert!</li>
                </ul>
                <div className="buttons">
                  <Button
                    label="Plaats gratis je standplaats"
                    linkOutside="/stand-truck-huren/locatie-verhuren/"
                    color="grey"
                    icon={spotGroupIconWhite}
                  />
                  <Button
                    label="Bekijk alle stands & trucks"
                    link="/?direction=search&search_model=stand"
                    color="grey-ghost"
                    icon={standIcon}
                  />
                </div>
              </div>
              <div className="col img">
                <img src={rentLocation} alt=""/>
              </div>
            </div>
            <div className="center-child">
              <Button
                label="Direct beginnen!"
                link="/wizard/welkom/start"
                linkState={{
                  buttonPrev: {
                    start: {
                      onClick: {navigate: location.pathname},
                      className: 'btn-red',
                      label: 'Terug',
                    }
                  }
                }}
                color="blue"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="location-types">
        <div className="section-container">
          <h1>Vind eenvoudig de perfecte <img src={spotGroupIcon} alt="locatie"/> locatie & standplaatsen voor jouw <img
            src={standIcon} alt="stand of truck"/> onderneming!</h1>
          <div className="content">
            <div className="row">
              <a
                className="col block"
                onClick={handleClickMarked}
                href={'/?direction=search&search_model=spot_group'}
              >
                <img src={market} alt=""/>
                <div className="text">
                  <h2>Op de markt</h2>
                  <p>Alle markten</p>
                </div>
              </a>
              <a
                className="col block"
                onClick={handleClickEvent}
                href={'/?direction=search&search_model=spot_group'}
              >
                <img src={event} alt=""/>
                <div className="text">
                  <h2>Op een evenement</h2>
                  <p>Alle evenementen</p>
                </div>
              </a>
              <a
                className="col block"
                onClick={handleClickPublic}
                href={'/?direction=search&search_model=spot_group'}
              >
                <img src={publicRoad} alt=""/>
                <div className="text">
                  <h2>Openbare weg</h2>
                  <p>Alle locaties in de openbare ruimte</p>
                </div>
              </a>
            </div>
          </div>
          <p>Naast markten en evenementen of de openbare weg zijn er nog tientalle andere type locaties beschikbaar op
            Standplaats.nl!</p>
          <ul>
            <li>Snel en gemakkelijk zoeken naar de ideale standplaats</li>
            <li>Duidelijke informatie over elke locatie</li>
            <li>Boek met een paar klikken je gewenste plek</li>
            <li>Geschikt voor elke ondernemer, ongeacht leeftijd of ervaring</li>
          </ul>
          <div className="buttons">
            <Button
              label="Meer info"
              color="grey"
              icon={standIconWhite}
              linkOutside="/standplaats-huren/zoek-een-standplaats/"
            />
            <Button
              label="Direct beginnen!"
              link="/wizard/welkom/start"
              color="blue"
              linkState={{
                buttonPrev: {
                  start: {
                    onClick: {navigate: location.pathname},
                    className: 'btn-red',
                    label: 'Terug',
                  }
                }
              }}
            />
          </div>
        </div>
      </section>


      <section className="fruit-market">
        <div className="section-container">
          <img src={fruitMarket} alt="Fruit markt Nederland"/>
        </div>
      </section>


      <section className="location-types">
        <div className="section-container">
          <h1>Bied moeiteloos je <img src={spotGroupIcon} alt="locatie"/> locatie & standplaatsen aan en bereik de
            juiste <img src={standIcon} alt="Stand of truck"/> mobile ondernemers / stands / trucks </h1>
          <div className="content">
            <div className="row">
              {/*<a*/}
              {/*  className="col block"*/}
              {/*  onClick={handleClickMarked}*/}
              {/*  href={'/?direction=search&search_model=spot_group'}*/}
              {/*>*/}
              {/*  <img src={market} alt=""/>*/}
              {/*  <div className="text">*/}
              {/*    <h2>Op de markt</h2>*/}
              {/*    <p>Alle markten</p>*/}
              {/*  </div>*/}
              {/*</a>*/}
              {/*<a*/}
              {/*  className="col block"*/}
              {/*  onClick={handleClickEvent}*/}
              {/*  href={'/?direction=search&search_model=spot_group'}*/}
              {/*>*/}
              {/*  <img src={event} alt=""/>*/}
              {/*  <div className="text">*/}
              {/*    <h2>Op een evenement</h2>*/}
              {/*    <p>Alle evenementen</p>*/}
              {/*  </div>*/}
              {/*</a>*/}
              {/*<a*/}
              {/*  className="col block"*/}
              {/*  onClick={handleClickPublic}*/}
              {/*  href={'/?direction=search&search_model=spot_group'}*/}
              {/*>*/}
              {/*  <img src={publicRoad} alt=""/>*/}
              {/*  <div className="text">*/}
              {/*    <h2>Openbare weg</h2>*/}
              {/*    <p>Alle locaties in de openbare ruimte</p>*/}
              {/*  </div>*/}
              {/*</a>*/}
            </div>
          </div>
          <ul>
            <li>Plaats eenvoudig je locatie op ons platform</li>
            <li>Bereik een breed scala aan mobiele ondernemers</li>
            <li>Beheer reserveringen en aanvragen met gemak</li>
            <li>Geschikt voor evenementenorganisatoren, marktmeesters en meer</li>
            <li>Vergroot de zichtbaarheid en aantrekkelijkheid van je locatie</li>
          </ul>
          <div className="buttons">
            <Button
              label="Meer info"
              icon={spotGroupIconWhite}
              color="grey"
              linkOutside="/stand-truck-huren/locatie-verhuren/"
            />
            <Button
              label="Direct beginnen!"
              link="/wizard/welkom/start"
              color="blue"
              linkState={{
                buttonPrev: {
                  start: {
                    onClick: {navigate: location.pathname},
                    className: 'btn-red',
                    label: 'Terug',
                  }
                }
              }}
            />
          </div>
        </div>
      </section>

      <section className="contact">
        <div className="section-container">
          <div className="content">
            <div className="row">
              <div className="col img">
                <img src={coffeeBarista} alt=""/>
              </div>
              <div className="col text">
                <h3>Laten we samen een kopje koffie drinken!</h3>
                <p>Zo kunnen we ontdekken hoe wij jou het beste kunnen ondersteunen.</p>
                <Button
                  label="Maak een afspraak"
                  icon={coffeeIcon}
                  linkOutside={'/service/contact/'}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<section className="reviews">*/}
      {/*  <div className="section-container">*/}
      {/*    <div className="content">*/}
      {/*      <div className="row reviews">*/}
      {/*        <div className="col block">*/}
      {/*          <p>‘Op Standplaats.nl heb ik een geschikte markt kunnen vinden voor op de dinsdagen’</p>*/}
      {/*          <p className="name">Martin Bosbeek</p>*/}
      {/*        </div>*/}
      {/*        <div className="col block">*/}
      {/*          <p>‘Via Standplaats.nl heb ik de juiste mobiele ondernemers gevonden die voor de winkel kunnen*/}
      {/*            staan’</p>*/}
      {/*          <p className="name">Jantine van Meersel</p>*/}
      {/*        </div>*/}
      {/*        <div className="col block">*/}
      {/*          <p>‘Ik ben altijd opzoek naar nieuwe ondernemers voor op onze festivals’</p>*/}
      {/*          <p className="name">Bakkerij Banini</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}


    </div>
  )
}

export default HowItWorks
