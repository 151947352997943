import axios from "axios";
import {localhost, debug, backendUrl} from '../env'
import useFunction from "../hooks/useFunction";
import swal from "sweetalert";

const useApi = () => {

  const {c, getToken, removeToken} = useFunction()



  /**
   * The api function
   *
   * @param method
   * @param endPoint
   * @param callbackSuccess
   * @param callbackValidationErrors
   * @param callbackFailed
   * @param data
   * @param needsCsrf
   * @param callbackSwal
   * @param timeout //120 sec.
   *
   * This API checks for the following http status codes:
   *
   *  - 200 -> oke -> callbackSuccess()
   *  - 204 -> Empty body ->
   *  - 400 -> bad request; validation errors -> callbackValidationErrors()
   *  - 422 -> Unprocessable Entity; validation errors -> callbackValidationErrors()
   *
   *  - 401 -> unauthorized -> callbackFailed()
   *  - 403 -> forbidden -> callbackFailed() todo, do something with with user_level
   *  - 404 -> not found -> callbackFailed()
   *
   *  - 419 -> Expired token -> callbackFailed()
   *
   *  - 429 -> Too many requests -> callbackFailed()
   *  - 500+ -> server errors -> callbackFailed()
   *
   */
  const api = (
    method,
    endPoint,
    callbackSuccess,
    callbackValidationErrors,
    callbackFailed,
    data = null,
    needsCsrf = false, //todo check if this even works!?
    callbackSwal = () => {
    },
    timeout = 120000,
  ) => {

    /**
     * if debug is on, add XDEBUG_SESSION_START flag with session id to request
     */
    if (localhost) {
      // endPoint +='?XDEBUG_SESSION_START='+Math.random().toString()
    }

    /**
     * Setup axios
     *
     * //todo setup and destroy with useEffects hooks, to remove potential memory leeks
     *    (the reason why this is a problem is that return callbacks can set in motion
     *    all kinds of functions that are not even used because the page has changed already
     *    calls should be inside useEffect Hook! so when this hook is destroyed, the call also is..
     *    now this is a HOOK. so use navigate instead of window.location... (but its not working as we speak)
     *
     *
     */
    const requestApi = axios.create({
      baseURL: backendUrl,
      timeout: timeout,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    c('API created (axios.create)', [requestApi])

    requestApi.interceptors.request.use(
      function (config) {
        config.headers.Authorization = getToken() ? `Bearer ${getToken()}` : ''

        // if u add new Chainable promise or other interceptor
        // You have to return `config` inside a request
        return config;
      }, function (error) {
        return Promise.reject(error);
      });


    /**
     * Add axios interceptors to catch http errors
     */
    requestApi.interceptors.response.use(
      (response) => {
        c('====================================================')
        c('=== INTERCEPTOR: Fulfilled: ' + response.status, response)

        if (response) {
          switch (response.status) {
            case 200:
              /*
               * 200: OK
               */

              return response
            case 204:
              /*
               * 204: OK, but empty
               */

              return response
            case 300:
              /*
               * 300: Multiple Choices. A message and buttons is shown.
               */

              c('300 (swal)', response.data)

              if ('swal' in response.data && response.data.swal) {
                swal({
                  ...response.data.swal,
                }).then((result) => {
                  if (result) {
                    Object.keys(response.data.swal.buttons).map(key => {
                      const button = response.data.swal.buttons[key]
                      if (result === button.value) {

                        if (button.action === 'navigate') {
                          window.location.href = button.url
                        }

                        /* could add more button actions here */

                      }
                      return button
                    })
                  }
                })
              }
              callbackSwal(response)
              break;

            /*
             * 404: not found
             */
            case 404:
              c('404 response', response)
              swal({
                title: 'Niet gevonden',
                text: '',
                closeOnEsc: false,
                closeOnClickOutside: false,
                buttons: {
                  confirm: {
                    text: 'oke',
                    value: true,
                    visible: true,
                    className: 'btn-color-green btn-size-m',
                    closeModal: true
                  }
                }
              })
              callbackFailed(response)
              break;

            case 400:
              /*
               * 400: bad request; Cannot show/update: show popup with custom warning/text
               */

              swal({
                title: response.data.message,
                text: response.data.errors.join('\n'),
                buttons: {
                  confirm: {
                    text: 'oke',
                    value: true,
                    visible: true,
                    className: 'btn-color-green btn-size-m',
                    closeModal: true
                  }
                }
              }).then((result) => {
                if (result) {
                  callbackValidationErrors(response.data.errors)
                }
              })


              c('400', response.data)
              break;
            case 422:
              /*
               * 422: Unprocessable Entity: form validation errors
               */

              c('422', response.data)
              callbackValidationErrors(response.data.errors)
              break;
            default:
              c('=== INTERCEPTOR: Fulfilled, but UNCAUGHT in switch. response:', response)
              return response;
          }

        } else {
          c('INTERCEPTOR: EMPTY RESPONSE ... SHOULD NOT SEE THIS ANY MORE!!!')
        }
      },
      (error) => {

        c('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', error)


        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

          c('@@@ INTERCEPTOR (response): ', error.response)

          switch (error.response.status) {

            case 401: //unauthorized
              c('401', error)
              removeToken()
              swal({
                title: 'Geen toegang',
                buttons: {
                  cancel: {
                    text: "Annuleren",
                    value: false,
                    visible: true,
                    closeModal: true,
                    className: 'btn-color-grey btn-size-m',
                  },
                  confirm: {
                    text: "Opnieuw inloggen",
                    value: true,
                    visible: true,
                    className: 'btn-color-green btn-size-m',
                    closeModal: true
                  },
                }

              }).then((result) => {
                if (result) {
                  window.location.href = "/inloggen"
                }
              })

              callbackFailed(error)
              break;

            /*
             * 403: forbidden
             */
            case 403:
              c('403', error)
              if (error.response.data.message === 'Your email address is not verified.') {
                swal({
                  title: 'Mail niet geverifieerd',
                  text: 'Opnieuw een verificatie email sturen?',
                  buttons: {
                    cancel: {
                      text: 'Uitloggen',
                      value: null,
                      visible: true,
                      className: 'btn-color-grey btn-size-m',
                      closeModal: true,
                    },
                    confirm: {
                      text: "Stuur e-mail",
                      value: true,
                      visible: true,
                      className: 'btn-color-green btn-size-m',
                      closeModal: true
                    }
                  }
                }).then((result) => {
                  if (result) {
                    api(
                      'get',
                      'email/verify/new',
                      () => {
                        removeToken()
                        window.location.href = "/email/verification"
                      },
                      () => {
                        callbackFailed(error)
                      },
                    )
                  } else {
                    removeToken()
                    window.location.href = "/"
                  }
                })
              } else {
                swal({
                  title: 'Geen toegang',
                  text: 'Zorg ervoor dat u de juiste rechten heeft, of neem contact met ons op.',
                  buttons: {
                    confirm: {
                      text: "OK",
                      value: true,
                      visible: true,
                      className: 'btn-color-green btn-size-m',
                      closeModal: true
                    },
                  }
                }).then((result) => {
                  // if (result) {
                  // removeToken()
                  // window.location.href = "/service/contact/"
                  // }
                })
                callbackFailed(error)
              }
              break;

            /*
             * 419: Expired token
             */
            case 419: //Expired token
              c('419', error)
              removeToken()
              swal({
                title: 'Sessie verlopen',
                buttons: {
                  confirm: {
                    text: "Opnieuw inloggen",
                    value: true,
                    visible: true,
                    className: 'btn-color-green btn-size-m',
                    closeModal: true
                  }
                }

              }).then((result) => {
                window.location.href = "/inloggen"
              })
              break;

            /*
             * 429: Too many requests
             */
            case 429:
              c('429', error)
              swal({
                title: 'Please wait...',
                closeOnEsc: false,
                closeOnClickOutside: false,
                buttons: false,
                timer: 10000,
              })

              callbackFailed(error)
              break;
            default:
              let text = 'Onze excusses voor het ongemak! Probeer het later nog eens.'
              if (debug) {
                text += '\n\n' + error
              }

              /*
               * 500 amd more: server errors
               */
              if (500 >= error.response.status) {
                sendErrorReport('Oeps! Server Error', 'Sorry!. Onze developers zijn op de hoogte gesteld. We gaan meteen aan de slag om het probleem te verhelpen.', error, {
                  errorResponseData: error.response.data,
                  errorResponse: error.response,
                  error: error,
                  url: 'url: ' + window.location.href + ' endPoint: ' + endPoint,
                })
              } else {
                sendErrorReport('Onbekende Error', 'Sorry!. Onze developers zijn op de hoogte gesteld. We gaan meteen aan de slag om het probleem te verhelpen.', error, {
                  errorResponseData: error.response.data,
                  errorResponse: error.response,
                  error: error,
                  url: 'url: ' + window.location.href + ' endPoint: ' + endPoint,
                })
              }
              c('500+', error)
          }

        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js

          c('@@@ INTERCEPTOR (request): ', error.request)
          swal('ERROR: no response', 'Geen antwoord van de server. Check uw internet verbinding. Timeout: ' + error.request.timeout / 1000 + ' sec.', 'error')
        } else {
          // Something happened in setting up the request that triggered an Error
          c('@@@ INTERCEPTOR (other/uncaught): ', error.message)
          swal('ERROR: other/uncaught', error.message, 'error')
        }
        return Promise.reject(error);
      });

    /**
     *
     * @param callback
     */
    const getCsrf = (callback) => {
      c('\n API getCSRF')
      requestApi.get('/sanctum/csrf-cookie').then(csrfResponse => {
        return callback(csrfResponse)
      })
    }

    /**
     *
     * @param endPoint
     * @param data
     */
    const call = (endPoint, data) => {
      c('\n API CALL >>> data: ', data)

      if (method === 'post') {
        requestApi.post(`api/` + endPoint, data, configStatusCodes())
          .then(response => {
            //success!
            if (response && 'data' in response) {
              callbackSuccess(response.data) //sends {data:{}, message:{}}
            } else {
              c('EMPTY RESPONSE... something wrong here?. response: ', response)
            }
          })

      } else {
        requestApi.get(`api/` + endPoint, configStatusCodes())
          .then(response => {
            if (response && 'data' in response) {
              callbackSuccess(response.data) //sends {data:{}, message:{}}
            } else {
              c('EMPTY RESPONSE... something wrong here?. response: ', response)
            }
          })
      }
    }

    /**
     * Except status code from showing up as an error
     *
     * @return {{validateStatus: (function(*))}}
     */
    const configStatusCodes = () => {
      return {
        validateStatus: (status) => {
          return status === 200 ||
            status === 204 ||
            status === 400 ||
            status === 404 ||
            status === 422 ||
            status === 300
        }
      }
    }

    /**
     *
     */
    if (needsCsrf) {
      getCsrf(() => call(endPoint, data, callbackSuccess, callbackFailed))
    } else {
      call(endPoint, data, callbackSuccess, callbackFailed)
    }
  }

  /**
   * Send error report to system admin
   *
   * @param title
   * @param text
   * @param errorText
   * @param data
   */
  const sendErrorReport = (title, text, errorText, data) => {
    if (!localhost){


      // api(
      //   'post',
      //   'error/send_report',
      //   () => {
      //     // window.location.href = "/error"
      //   },
      //   () => {
      //   },
      //   () => {
      //     // window.location.href = "/service/contact/"
      //   },
      //   {
      //     data: data,
      //   }
      // )


    }

    swal(
      {
        title: title,
        text: text,
        content: errorText,
        icon: 'error',
        closeOnClickOutside: false,
        className: 'swal-error-report',
        buttons: {
          confirm: {
            text: 'Pagina vernieuwen',
            value: true,
            visible: true,
            className: 'btn-color-grey btn-size-m',
            closeModal: true,
          },
          cancel: {
            text: "Doorgaan",
            value: null,
            visible: true,
            className: 'btn-color-green btn-size-m',
            closeModal: true
          }
        }
      }).then((result) => {


      if (result) {
        !localhost && window.location.reload()

        //todo send email to chris dat er een klant last van deze error heeft.

        // window.location.href = "/error"

        // api(
        //   'post',
        //   'error/send_report',
        //   () => {
        //     window.location.href = "/error"
        //   },
        //   () => {
        //   },
        //   () => {
        //     window.location.href = "/service/contact/"
        //   },
        //   {
        //     data: data,
        //   }
        // )

      } else {
        // !localhost && window.location.reload()
      }

    })
  }
  return {api, sendErrorReport}
}

export default useApi
