import React from "react";
import {Form, Input, Select, SelectArray, Media, Fields, Static} from "../../components/Form/Form";
import countryCodes from "../../data/countryCodes";
import AuthContext from "../../context/AuthContext";
import Button from "../../components/Button/Button";
import useUserService from "../../api/services/useUserService";
import notificationIcon from "../../assets/icons/email-notification-white.svg"
import useForm from "../../hooks/useForm";
function UserAccount() {

  const userService = useUserService()
  const form = useForm()

  //todo overal waar het USER OBJECT aangepast wordt, gebruik <AuthContext.Consumer> en setUser, zodat deze overal up-to-date blijft

  return (
    <div>
      <h1>Gebruiker</h1>
      <AuthContext.Consumer>
        {
          ({setUser, user}) => {
            return (
              <Form>
                <Fields
                  id={user.id}
                  form={form}
                  apiService={userService}
                  updateSuccess={(user)=> setUser(user)}
                  GAEventLabel="User account Edit"
                  preService={(model) => {
                    model.companies = null
                    return model
                  }}
                >
                  <Input
                    label="Voornaam"
                    name="first_name"
                  />
                  <Input
                    label="Achternaam"
                    name="last_name"
                  />
                  <Input
                    label="Telefoon"
                    name="phone"
                  />
                  {/*<Static*/}
                  {/*  label="Overige Instellingen"*/}
                  {/*  name="settings"*/}
                  {/*/>*/}
                  <SelectArray
                    label="Taal"
                    name="language_code"
                    data={countryCodes}
                  />
                  <Select
                    label="Actief account/organisatie"
                    name="active_company_id"
                    relation="companies"
                    relationLabel="legal_name"
                  />
                  <Media
                    label="Avatar"
                    name="avatar_media"
                    width={120}
                    height={120}
                    size="logo"
                  />
                  <br/>
                  <Static
                    label="Emailadres"
                    name="email"
                  />
                  <Button
                    label="Emails & Meldingen"
                    link={'/user/notificaties'}
                    icon={notificationIcon}
                  />
                  <br/>
                  <Button
                    label="Emailadres wijzigen"
                    link={'/user/account/email/wijzigen'}
                  />
                  <br/>
                  <Button
                    label="Wachtoord wijzigen"
                    link={'/user/account/wachtwoord/wijzigen'}
                  />
                </Fields>
              </Form>
            )
          }
        }
      </AuthContext.Consumer>
    </div>
  )
}

export default UserAccount
