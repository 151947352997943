import useFunction from "../../hooks/useFunction";
import useApi from "../../api/useApi";

const useCompanyService = () => {

  const {c} = useFunction()
  const {api} = useApi()

  const adminShowCompaniesCollection = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'admin/companies',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const adminShowCompanyUserProgress = (callbackSuccess, callbackValidationErrors) => {
    return api(
      'get',
      'admin/company-users-progress',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    objectType:'admin_company',
    adminShowCompaniesCollection,
    adminShowCompanyUserProgress,
  }
}

export default useCompanyService
