import React, {useEffect, useState} from "react";
import {localhost} from "../../env";
import paymentStatusEnum from "../../data/paymentStatusEnum";
import usePaymentService from "../../api/services/usePaymentService";
import useFunction from "../../hooks/useFunction";
import usePrice from "../../hooks/usePrice";
import Loading from "../../components/Loading/Loading";
import Button from "../../components/Button/Button";
import "./style.scss"

const UserPayments = () => {

  const {dateTime} = useFunction()
  const {userShow} = usePaymentService()
  const [payments, setPayments] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const {formatPrice} = usePrice()

  useEffect(() => {
    userShow(
      (payments) => {
        setPayments(payments)
        setIsLoading(false)
      },
      () => {

      }
    )
  }, [])

  const getPaymentStatusLabel = (value) => {
    return paymentStatusEnum.find(status => status.value === value).label
  }

  return (
    <div className="page-user-payments">
      <h1>Betalingen</h1>

      <div className="row">
        <Button
          label="Boekingsoverzicht"
          link={'/user/boekingen'}
        />
      </div>

      {isLoading ?
        <Loading/>
        :
        <div className="payments">
          {payments && !!payments.length
            ? payments.map((payment, i) => {

              return (
                <div key={i} className={'payment'}>
                  <div className="fields">

                    {localhost &&
                      <div className="field status">
                        <p className={"label"}>ID:</p>
                        <p>{payment.id}</p>
                      </div>
                    }

                    <div className="field status">
                      <p className={"label"}>Status:</p>
                      {getPaymentStatusLabel(payment.status)}
                    </div>

                    {payment.booking &&
                      <div className="field booking">
                        <p className={"label"}>Boeking ID:</p>
                        <p>{payment.booking.booking_number}</p>
                      </div>
                    }

                    {payment.amount &&
                      <div className="field amount">
                        <p className={"label"}>Bedrag (incl. btw):</p>
                        <p>{formatPrice(payment.amount)}</p>
                      </div>
                    }

                    {/*{payment.user &&*/}
                    {/*  <div className="field user">*/}
                    {/*    <p className={"label"}>Betaald door:</p>*/}
                    {/*    <p>{payment.user.first_name}</p>*/}
                    {/*    <p>{payment.user.email}</p>*/}
                    {/*  </div>*/}
                    {/*}*/}

                    {payment.payment_url && payment.status === 'open' &&
                      <div className="field pay">
                        <Button
                          label="betalen"
                          linkOutside={payment.payment_url}
                          target="_blank"
                        />
                      </div>
                    }


                    <div className="field meta">
                      <p className={"label"}>Aangevraagd:</p>
                      <p>{dateTime(payment.created_at)}</p>
                      <p className={"label"}> Geupdate:</p>
                      <p>{dateTime(payment.updated_at)}</p>
                    </div>

                  </div>
                </div>
              )
            })
            : <p>Nog geen betalingen</p>
          }
        </div>
      }
    </div>
  )
}

export default UserPayments
