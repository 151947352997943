import React from "react";
import {Form, Select, Fields} from "../../components/Form/Form";
import AuthContext from "../../context/AuthContext";
import Button from "../../components/Button/Button";
import useUserService from "../../api/services/useUserService";
import useForm from "../../hooks/useForm";
import companyIcon from "../../assets/icons/company-white.svg";

const UserAccountActiveCompany = () => {

  const userService = useUserService()
  const form = useForm()

  return (
    <div>
      <h1>Actief account/organisatie</h1>
      <p>Acties, zoals boeken, berichten versturen, doe je altijd namens een account/organisatie. Daarom moet je altijd een 'actieve account/organisatie' hebben. Naast de account/organisatie naam is de naam van de gebruiker die de actie uitgevoerd heeft ook vaak zichtbaar.</p>
      <Button
        label="Account/organisaties beheren"
        link="/user/organisaties"
        icon={companyIcon}
      />
      <br/>
      <br/>
      <br/>
      <AuthContext.Consumer>
        {
          ({setUser, user}) => {
            return (
              <Form>
                <Fields
                  id={user.id}
                  form={form}
                  apiService={userService}
                  updateSuccess={(user)=> setUser(user)}
                  GAEventLabel="User Active Company"
                >
                  <Select
                    label="Actief account/organisatie"
                    name="active_company_id"
                    relation="companies"
                    relationLabel="legal_name"
                  />
                </Fields>
              </Form>
            )
          }
        }
      </AuthContext.Consumer>
    </div>
  )
}

export default UserAccountActiveCompany
