import React from "react";
import PropTypes from "prop-types";
import './Static-style.scss'

const Static = (props) => {

  const {
    label = '',
    value = '',
    className = '',
    hasError = false,
    error = '',
    isLoading,
  } = props

  return (
    <div className={
      className + 'static ' }>
      <label>{label}
        {hasError && <span className="error"> - {error}</span>}
        <div className={isLoading ? 'content disabled-loading' : 'content'}>
          {value}
        </div>
      </label>
    </div>
  )
}

Static.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  className: PropTypes.string,
  hasError: PropTypes.bool,
  error: PropTypes.string,
}

export default Static
