import useFunction from "../../hooks/useFunction";
import useApi from "../../api/useApi";

const useCompanyService = () => {

  const {c} = useFunction()
  const {api} = useApi()

  const userShow = (callbackSuccess, callbackValidationErrors, companyId = null) => {
    return api(
      'get',
      (companyId) ? 'user/company/' + companyId : 'user/companies',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userUpdate = (callbackSuccess, callbackValidationErrors, companyId, data) => {
    return api(
      'post',
      (companyId) ? 'user/company/' + companyId + '/update' : 'user/companies/update',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const userStore = (callbackSuccess, callbackValidationErrors, companyId, data) => {
    return api(
      'post',
      'user/companies/store',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const userValidateIban = (callbackSuccess, callbackValidationErrors, companyId, callbackUrl) => {
    return api(
      'post',
      'user/company/'+companyId+'/validate/iban',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      {callback_url:callbackUrl},
    )
  }

  const userValidateIbanCallback = (
    callbackSuccess,
    callbackValidationErrors,
    companyId,
    paymentKey,
  ) => {
    return api(
      'post',
      'user/company/'+companyId+'/validate/iban/callback/'+paymentKey,
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  const userAddUser = (callbackSuccess, callbackValidationErrors, companyId, data) => {
    return api(
      'post',
      'user/company/'+companyId+'/user/invite',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
      data,
    )
  }

  const userDelete = (callbackSuccess, callbackValidationErrors, companyId, callbackError) => {
    return api(
      'post',
      'user/company/' + companyId + '/delete',
      response => callbackSuccess(response.data),
      errors => callbackValidationErrors(errors),
      error =>  c('Error in Service', error),
    )
  }

  return {
    objectType:'company',
    userShow,
    userUpdate,
    userStore,
    userValidateIban,
    userValidateIbanCallback,
    userAddUser,
    userDelete
  }
}

export default useCompanyService
