import React from "react";
import types from "../../data/types";
import industries from "../../data/industries";
import initSpotGroup from "../../data/initSpotGroup";
import {
  Fields,
  Form,
  Input,
  Media,
  Options,
  Price,
  SelectBlock,
  Slug,
  Textarea
} from "../../components/Form/Form";
import {Route, useNavigate, useParams} from "react-router-dom";
import WizardAddSpotGroupStart from "./WizardAddSpotGroupStart";
import useSpotGroupService from "../../api/services/useSpotGroupService";
import SocialShareProfile from "../../components/SocialShareProfile/SocialShareProfile";
import ProfileStatusForm from "../../components/Form/ProfileStatusForm";
import useOptionsService from "../../api/services/useOptionsService";
import useSpotService from "../../api/services/useSpotService";
import Availabilities from "../../components/Form/Availabilities";
import BusinessHour from "../../components/Form/BusinessHour";
import SpotMapForm from "../../components/SpotMap/SpotMapForm";
import useWizard from "../../hooks/useWizard";
import Location from "../../components/Form/Location";
import Address from "../../components/Form/Address";
import useForm from "../../hooks/useForm";
import Wizard from "../../layouts/Wizard/Wizard";
import Three from "../../components/Form/Three";
import Bool from "../../components/Form/Bool";
import spotGroupIcon from "../../assets/icons/spot-group.svg"
import markedImage from "../../assets/images/example-images/markt.jpg"

const WizardAddSpotGroup = () => {

  const params = useParams()
  const form = useForm()
  const pathname = '/wizard/locatie'
  const steps = [
    {slug: 'start', label: 'Alle locaties'},
    {slug: 'new', label: 'Algemeen'},
    {slug: 'type', label: 'Soort'},
    {slug: 'map', label: 'Locatie'},
    {slug: 'address', label: 'Adres'},
    {slug: 'images', label: 'Fotos'},
    {
      slug: 'profile', label: 'Profiel',
      next: {
        key: 'public',
        value: 0,
        goTo: 'finish'
      }
    },
    {slug: 'availabilities', label: 'Beschikbaarheid'},
    {slug: 'business_hours', label: 'Openingstijden'},
    {
      slug: 'is_multi_spot', label: 'Aantal standplaatsen',
      next: {
        key: 'multi_spot',
        value: 0,
        goTo: 'price_single'
      }
    },
    //todo price could also be 1 slide. now its decided between

    /* Steps for multi-spot scenario */
    {slug: 'price_multi', label: 'Prijs'},

    {
      slug: 'spots', label: 'Standplaats Plattegrond',
      next: {
        key: 'multi_spot',
        value: 1,
        goTo: 'options'
      }
    }, //todo later bij booking van stand: laten zien voor welke spot een stand of truck geboekt wordt.

    /* Steps for single-spot scenario */
    {
      slug: 'price_single', label: 'Prijs',
      prev: {
        key: 'multi_spot',
        value: 0,
        goTo: 'is_multi_spot'
      }
    },
    {slug: 'single_spot_general', label: 'Standplaats Algemeen'}, //label, description, length, width
    {slug: 'single_spot_industry', label: 'Standplaats Branche'}, //? //industry
    {slug: 'single_spot_image', label: 'Standplaats Afbeelding'}, //image
    {
      slug: 'options', label: 'Opties',
      prev: {
        key: 'multi_spot',
        value: 1,
        goTo: 'spots'
      }
    },
    // {slug: 'options_requested', label: 'Opties'},
    // {slug: 'info', label: 'Belangrijke Informatie'},
    // {slug: 'info_requested', label: ''},
    {
      slug: 'finish', label: 'Finish',
      prev: {
        key: 'public',
        value: 0,
        goTo: 'profile',
      }
    },
  ]

  const initialButtonPrev = {
    start: {
      onClick: () => navigate('/wizard/welkom/choice_stand_truck_location/') //goes to last page of welcome wizard
    },
  }

  const paramsSplit = params['*'].split('/')



  //todo disabled new button is not reloaded.. therefore when editing spotgroup the new next button is still disabled... :(
  const initialButtonNext = {
    start: {
      visible: false,
    },
    new: {
      disabled: !Number.isInteger(parseInt(paramsSplit[1])),
    },
    finish: {
      label: 'Zoek stands en trucks',
      onClick: () => navigate('/?direction=search&search_model=stand'),
    },
  }

  const updateSteps = (steps) => {
    return steps
  }

  const wizard = useWizard(
    steps,
    updateSteps,
    pathname,
    initialButtonPrev,
    initialButtonNext,
    form,
  )

  const spotGroupService = useSpotGroupService()
  const spotService = useSpotService()
  const optionService = useOptionsService('spot_group_option')
  // const infoService = useOptionsService('spot_group_info')
  // const optionRequestService = useOptionsService('stand_option')
  // const infoRequestService = useOptionsService('stand_info')
  const navigate = useNavigate()
  const formSpot = useForm()


  /**
   * fix spot length
   *
   * @param spot
   * @returns {*}
   */
  // const spotPreService = (spot) => {
  //   // spot.length = spot.height //note: 'length' is used in the database and backend. 'height' is used in the front-end.
  //   return spot
  // }

  return (
    <div className="wizard-add-spot-group">
      <Wizard
        form={form}
        wizard={wizard}
      >
        <Route path="/start/*" element={
          <div className="center">
            <h1><img
              src={spotGroupIcon}
              alt="Markten etc"
              style={{width: 24}}
            /> Alle locaties</h1>
            <WizardAddSpotGroupStart
              wizard={wizard}
            />
          </div>
        }/>

        <Route path="/new/*" element={
          <div className="center">
            {wizard.id ?
              <h2>Locatie bewerken</h2>
              :
              <h2>Nieuwe locatie</h2>
            }
            <div className="width-small">
              <Form>
                <Fields
                  id={wizard.id}
                  form={form}
                  wizard={wizard}
                  initData={initSpotGroup}
                  apiService={spotGroupService}
                  updateSuccess={(spotGroup) => wizard.nextStep(spotGroup.id)}
                  onChange={() => wizard.nextButtonDisabled()}
                  onReset={() => wizard.setInitialButtons()}
                >
                  <Input
                    label="Naam van de locatie"
                    name="label"
                    placeholder="Geef je locatie een naam"
                    autoFocus="autofocus"
                  />
                  <Textarea
                    label="Beschrijving"
                    name="description"
                    placeholder="Vertel ons meer over de locatie"
                  />
                </Fields>
              </Form>
            </div>
          </div>
        }/>
        {wizard.id &&
          <>
            <Route path="/type/*" element={
              <div className="center">
                <h2>Soort locatie</h2>
                <div className="width-600">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <SelectBlock
                        label="Vink aan wat voor locatie dit is (je kunt meerdere vakjes aankruisen)"
                        name="types_ids"
                        data={types}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/map/*" element={
              <div className="center">
                <h2>Locatie</h2>
                <div className="width-full">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Location
                        label="Klik op de kaart om de locatie aan te geven"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/address/*" element={
              <div className="center">
                <h2>Locatie Adres</h2>
                <div className="width-full">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Address
                        text="Zichtbaar na een afgeronde boeking, zodat de stand/truck/act naar deze locatie kan navigeren."
                        name="address"
                        autoFocus="autofocus"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/images/*" element={
              <div className="center">
                <h2>Foto's toevoegen van de locatie</h2>
                <div className="width-600">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Media
                        label="1. Vooraanzicht van de locatie"
                        name="overview_media"
                        width={1600}
                        height={700}
                        exampleImage={markedImage}
                        size="banner"
                      />
                      <Media
                        label="2. Andere afbeelding van locatie"
                        name="other_media"
                        width={900}
                        size="normal_full_height"
                      />
                      {/*//todo need anywhere else?*/}
                      {/*{'is_admin' in user && user.is_admin &&*/}
                      {/*  <Media*/}
                      {/*    label="Terrein achtergrond"*/}
                      {/*    name="spot_map_media"*/}
                      {/*    width={900}*/}
                      {/*    size="normal_full_height"*/}
                      {/*  />*/}
                      {/*}*/}
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/profile/*" element={
              <div className="center">
                <h2>Openbaar profiel aanmaken?</h2>
                <div className="width-full">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Bool
                        label="Wil je dat stand & trucks deze locatie kunnen vinden en boeken?"
                        name="public"
                      />
                      <Slug
                        label="Link naar profiel"
                        text="Voorbeeld: bio-kaasmarkt"
                        urlPostFix="locatie"
                        name="slug"
                        basedOnField="label"
                        enabledBy={[{key:'public', value:1}]}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/availabilities/*" element={
              <div className="center">
                <h2>Beschikbaarheid</h2>
                <div className="width-full">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Three
                        label="Wat is de status van deze locatie?"
                        name="available"
                        labelYes="Elke dag beschikbaar"
                        labelNo="(nog) niet beschikbaar (niet boekbaar en niet in zoekresultaten)"
                        labelOther="Specifieke dag(en) ingeven"
                      />
                      <Availabilities
                        label="Geef hier aan welke dagen de locatie niet beschikbaar bent."
                        name="availabilities"
                        enabledBy={[{key:'available', value:2}]}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/business_hours/*" element={
              <div className="center">
                <h2>Openingstijden</h2>
                <div className="width-full">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <BusinessHour
                        label="Openingstijden gebruiken"
                        name="business_hour"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/is_multi_spot/*" element={
              <div className="center">
                <h2>Aantal standplaatsen</h2>
                <p></p>
                <div>
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Bool
                        label="Hoeveel standplaatsen heeft deze locatie?"
                        name="multi_spot"
                        labelNo="1"
                        labelYes="Meerdere"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/price_multi/*" element={
              <div className="center">
                <h2>Prijs</h2>
                <div className="width-medium">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup, unChangedSpotGroup) => wizard.nextStep(undefined, unChangedSpotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Price
                        // label="Wat is de prijs waarvoor je de locatie zou willen aanbieden?"
                        name="price"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/spots/*" element={
              <div className="center">
                <div className="width-full">
                  <SpotMapForm
                    id={wizard.id}
                    form={form}
                    wizard={wizard}
                    onChange={() => wizard.navButtonsDisabled()}
                    onReset={() => wizard.setInitialButtons()}
                    updateSuccess={() => wizard.setInitialButtons()}
                  />
                </div>
              </div>
            }/>

            <Route path="/price_single/*" element={
              <div className="center">
                <h2>Prijs</h2>
                <div className="width-medium">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup, unChangedSpotGroup) => wizard.nextStep(undefined, unChangedSpotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Price
                        // label="Wat is de prijs waarvoor je de locatie zou willen aanbieden?"
                        name="price"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/single_spot_general/*" element={
              <div className="center">
                <h2>Standplaats Algemeen</h2>
                <div className="width-medium">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={formSpot}
                      wizard={wizard}
                      apiService={spotService}
                      parentShowApiService={spotGroupService.userShow}
                      parentApiServiceIdPath={'spots[0].id'}
                      updateSuccess={(spot) => wizard.nextStep(undefined, spot)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                      // preService={spotPreService}
                    >
                      <Input
                        label="ID/Label/Naam"
                        name="label"
                        autoFocus="autofocus"
                      />
                      <Textarea
                        label="Beschrijving"
                        name="description"
                      />
                      <Input
                        label="Lengte (verticaal)"
                        name="length" //note: 'length' is used in the database and backend. 'height' is used in the front-end.
                        type="meter"
                      />
                      <Input
                        label="Breedte (horizontaal)"
                        name="width"
                        type="meter"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/single_spot_industry/*" element={
              <div className="center">
                <h2>Standplaats Branche</h2>
                <div className="width-medium">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={formSpot}
                      wizard={wizard}
                      apiService={spotService}
                      parentShowApiService={spotGroupService.userShow}
                      parentApiServiceIdPath={'spots[0].id'}
                      updateSuccess={(spot) => wizard.nextStep(undefined, spot)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <SelectBlock
                        label="Alle producten/branches die je zoekt voor DEZE standplaats/spot/plek"
                        name="industries_ids"
                        data={industries}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/single_spot_image/*" element={
              <div className="center">
                <h2>Standplaats Afbeelding</h2>
                <div className="width-medium">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={formSpot}
                      wizard={wizard}
                      apiService={spotService}
                      parentShowApiService={spotGroupService.userShow}
                      parentApiServiceIdPath={'spots[0].id'}
                      updateSuccess={(spot) => wizard.nextStep(undefined, spot)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Media
                        label="Afbeelding van deze standplaats/spot/plek"
                        name="overview_media"
                        width={900}
                        height={450}
                        size="normal"
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            <Route path="/options/*" element={
              <div className="center">
                <h2>Opties</h2>
                <div className="width-medium">
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                      updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}
                      onChange={() => wizard.navButtonsDisabled()}
                      onReset={() => wizard.setInitialButtons()}
                    >
                      <Options
                        label="Geef hier aan welke opties en of mogelijkheden de locatie allemaal heeft. (denk aan stroom, kraam, etc)"
                        name="bookable_options"
                        apiService={optionService}
                        type="spot_group_option"
                        showPrice={true}
                      />
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>

            {/*<Route path="/options_requested/*" element={*/}
            {/*  <div className="center">*/}
            {/*    <h2>Opties</h2>*/}
            {/*    <div className="width-medium">*/}
            {/*      <Form>*/}
            {/*        <Fields*/}
            {/*          id={wizard.id}*/}
            {/*          form={form}*/}
            {/*          wizard={wizard}*/}
            {/*          apiService={spotGroupService}*/}
            {/*          updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}*/}
            {/*          onChange={() => wizard.navButtonsDisabled()}*/}
            {/*          onReset={() => wizard.setInitialButtons()}*/}
            {/*        >*/}


            {/*        </Fields>*/}
            {/*      </Form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}/>*/}

            {/*<Route path="/info/*" element={*/}
            {/*  <div className="center">*/}
            {/*    <h2>Belangrijke Informatie</h2>*/}
            {/*    <div className="width-medium">*/}
            {/*      <Form>*/}
            {/*        <Fields*/}
            {/*          id={wizard.id}*/}
            {/*          form={form}*/}
            {/*          wizard={wizard}*/}
            {/*          apiService={spotGroupService}*/}
            {/*          updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}*/}
            {/*          onChange={() => wizard.navButtonsDisabled()}*/}
            {/*          onReset={() => wizard.setInitialButtons()}*/}
            {/*        >*/}


            {/*        </Fields>*/}
            {/*      </Form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}/>*/}

            {/*<Route path="/info_requested/*" element={*/}
            {/*  <div className="center">*/}
            {/*    <h2>Belangrijke Informatie</h2>*/}
            {/*    <div className="width-medium">*/}
            {/*      <Form>*/}
            {/*        <Fields*/}
            {/*          id={wizard.id}*/}
            {/*          form={form}*/}
            {/*          wizard={wizard}*/}
            {/*          apiService={spotGroupService}*/}
            {/*          updateSuccess={(spotGroup) => wizard.nextStep(undefined, spotGroup)}*/}
            {/*          onChange={() => wizard.navButtonsDisabled()}*/}
            {/*          onReset={() => wizard.setInitialButtons()}*/}
            {/*        >*/}


            {/*        </Fields>*/}
            {/*      </Form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}/>*/}

            <Route path="/finish/*" element={
              <div className="center">
                <h2>Gefeliciteerd!</h2>
                <div className="width-medium">
                  <h4>Jouw locatie is aangemaakt!</h4>
                  <Form>
                    <Fields
                      id={wizard.id}
                      form={form}
                      wizard={wizard}
                      apiService={spotGroupService}
                    >
                      <ProfileStatusForm/>
                      {/*<ProfileExample/>*/}
                      <SocialShareProfile/>
                    </Fields>
                  </Form>
                </div>
              </div>
            }/>
          </>
        }
      </Wizard>
    </div>
  )
}

export default WizardAddSpotGroup
