import React from "react";
import PropTypes from "prop-types";
import useForm from "../../hooks/useForm";
import './Bool-style.scss'

const Bool = (props) => {

  const {getValue} = useForm()

  const {
    label = '',
    name = '',
    text = '',
    value = '',
    className = '',
    hasError = false,
    error = '',
    isLoading,
    afterLabel,
    reversed = false,
    formData,
    save,
    labelYes = 'Ja',
    labelNo = 'Nee',
  } = props

  /**
   *
   * @param e
   * @param value
   */
  const onChange = (e, value) => {
    formData.model[e.target.name] = (reversed) ? !value : value
    save()
  }

  return (
    <>
      <div className={className + 'bool '}>
        <div>{label}<br/><br/>
          {text && text !== '' &&
            <p className="text-small">{text}</p>
          }
          {hasError && <span className="error"> - {error}</span>}
          <div className="form-row">
            <label>{labelYes}
              <input
                disabled={isLoading}
                name={name}
                checked={(reversed) ? !getValue(value) : getValue(value)}
                onChange={(e) => onChange(e, 1)}
                type='radio'
              />
            </label>
            <label>{labelNo}
              <input
                disabled={isLoading}
                name={name}
                checked={(reversed) ? getValue(value) : !getValue(value)}
                onChange={(e) => onChange(e, 0)}
                type='radio'
              />
            </label>
            {afterLabel &&
              <span className="after-label">{afterLabel}</span>
            }
          </div>
        </div>
      </div>
    </>
  )
}

Bool.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  reversed: PropTypes.bool,
  labelYes: PropTypes.string,
  labelNo: PropTypes.string,
  form: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
}

export default Bool
