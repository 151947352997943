import React from "react";
import PropTypes from "prop-types";
import './style.scss'

const AnchorTitle = (props) => {

  const {
    label = '',
    id
  } = props

  return (
    <div
      className="component-anchor-title"
      id={'anchor-'+id}
    >
      <h1>{label}</h1>
    </div>
  )
}

AnchorTitle.propTypes = {
  label: PropTypes.string,
  id: PropTypes.number.isRequired,
}

export default AnchorTitle
