import React, {useState} from "react";
import {gsap} from "gsap";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {Calendar} from "react-multi-date-picker";
import SelectFilter from "../SelectFilter/SelectFilter";
import LocationForm from "../LocationForm/LocationForm";
import useFunction from "../../hooks/useFunction";
import BlockList from "../BlockList/BlockList";
import Button from "../Button/Button";
import Tabs from "../Tabs/Tabs";
import Tab from "../Tabs/Tab";
import {months, weekDays} from "../../data/calendar";
import initialFilter from "../../data/initialFilter";
import industries from "../../data/industries";
import types from "../../data/types";
import cloneDeep from 'lodash/cloneDeep';
import './style.scss'

const SearchFilter = (props) => {


  //todo due to changing the code all the time this comp. is not DRY. refactor. (create sub-components)

  const {
    filter,
    isChanged,
    search,
    showMap,
    isLoading,
    setFilterByPath,
    nCalendars,
    isMobile,
  } = props

  const location = useLocation()
  const [activeMenu, setActiveMenu] = useState(null)
  const navigate = useNavigate()
  const {getWithPath} = useFunction()

//todo implement
  // useEffect(() => {
  //   // Function to call when Enter is pressed
  //   const handleEnterPress = (event) => {
  //     if (event.key === 'Enter') {
  //       handleSearch(filter);
  //     }
  //   };
  //
  //   // Add event listener when component mounts
  //   window.addEventListener('keydown', handleEnterPress);
  //
  //   // Cleanup the event listener when component unmounts
  //   return () => {
  //     window.removeEventListener('keydown', handleEnterPress);
  //   };
  // }, []);

  /**
   *
   * @param id
   * @param bool
   */
  const toggleActiveMenu = (id, bool) => {
    if (bool === false) {
      search(filter)
      setActiveMenu(null)
      return
    }
    if (activeMenu !== id && isMobile){
      gsap.to(window, {duration: 0.5, scrollTo:
          {y: '[data-menu-id="'+id+'"]', offsetY: 80}, ease: "power2.inOut"});
    }
    setActiveMenu((activeMenu === id) ? null : id)
  }

  /**
   *
   * @param id
   * @returns {boolean}
   */
  const isActiveMenu = (id) => {
    return activeMenu === id
  }

  /**
   *
   */
  const handleSearch = (filter) => {
    if (isMobile){
      search(filter)
      setFilterByPath('direction', null)
    } else {
      setActiveMenu(null)
      search(filter)
    }
  }

  /**
   *
   * @param filter
   */
  const handleRegister = (filter) => {
    setActiveMenu(null)

    //todo pass filter object to wizard
    navigate('/wizard/welkom', {state: {
      filter: filter,
      buttonPrev:{start:{onClick:{navigate:location.pathname}}}
    }})
  }


  /**
   * Handle backdrop click
   */
  const handleBackdropClick = () => {
    search(filter)
    setActiveMenu(null)
  }

  /**
   * Get dynamic label for 'Type' data
   *
   * @param path
   * @param blockListData
   * @returns {string|null}
   */
  const getDynamicLabelBlockList = (path, blockListData) => {
    const data = getWithPath(filter, path)
    let label = ''
    if (
      //todo this is hacked (is more efficient, and lists are not suppose to change).
      // but this should be done in a better way
      // this is hack is also on the server side: file BookingController.php somewhere around line 182 and 205
      // also inside the SpotGroupFactory.php and the StandFactory.php
      (data.length === 33 && blockListData[0].label === "Op een markt") || //type
      (data.length === 104 && blockListData[0].label === "Food") //industries
    ) {
      return null
    } else {
      label += recursive(label, blockListData, data)
      return label
    }
  }

  /**
   * Recursive function to get dynamic label for 'getDynamicLabelBlockList' data
   * @param label
   * @param array
   * @param ids
   * @returns {*}
   */
  const recursive = (label, array, ids) => {
    array.map(type => {
      if (ids.includes(type.id)) {
        label += type.label + ', '
      }
      if ('children' in type) {
        label = recursive(label, type.children, ids)
      }
    })
    return label
  }

  /**
   * Get dynamic label for 'Waar' data
   *
   * @param path
   * @returns {string}
   */
  const getDynamicLabelLocation = (path) => {
    const data = getWithPath(filter, path)
    let label = ''
    label += data.city ? data.city + ', ' : ''
    label += data.postal_code ? data.postal_code + ', ' : ''
    label += (label !== '' && data.radius) ? data.radius + 'km' : ''
    return label
  }

  /**
   * Get dynamic label for 'Wanneer' data
   *
   * @param path
   * @returns {string}
   */
  const getDynamicLabelWhen = (path) => {
    const data = getWithPath(filter, path)
    let label = ''
    if (!data) return null

    if (data.length === 1) {
      label += data[0].format('D MMMM YYYY')
    }
    if (data.length === 2) {
      label += data[0].format('D MMMM YYYY') + ' - ' + data[1].format('D MMMM YYYY')
    }
    return label
  }

  /**
   * Handle reset by path
   *
   * @param path
   * @param toggleActiveMenuId
   */
  const handleResetByPath = (path, toggleActiveMenuId = false) => {
    setFilterByPath(path, getWithPath(cloneDeep(initialFilter), path))
    if (toggleActiveMenuId) {
      toggleActiveMenu(toggleActiveMenuId)
    }
  }

  /**
   * Handle select all: to set all ids from data array
   *
   * @param path
   * @param data
   */
  const handleSelectAll = (path, data) => {
    const ids = []
    const test = recursiveIds(ids, data);
    setFilterByPath(path, test)
  }

  /**
   * Recursive function to get all ids from data array
   *
   * @param ids
   * @param array
   * @returns {*}
   */
  const recursiveIds = (ids, array) => {
    array.map(item => {
      ids.push(item.id)
      if ('children' in item) {
        ids = recursiveIds(ids, item.children)
      }
    })
    return ids
  }

  return (
    <div className="component-search-filter">
      <Tabs
        activeTab={filter.direction}
        setActiveTab={id => setFilterByPath('direction', id)}
        isMobile={isMobile}
        initialCloseOnMobile={true}
      >
        <Tab
          label="Zoeken"
          id="search"
        >
          <Tabs
            activeTab={filter.search_model}
            setActiveTab={id => !isLoading && setFilterByPath('search_model', id, true)}
            design="buttons"
            noPadding={true}
            isMobile={isMobile}
          >
            <Tab
              label="Ik zoek een standplaats"
              id="spot_group"
            >
              <SelectFilter
                menuId={1}
                label="Type standplaats"
                fieldLabel="Alle type standplaatsen"
                dynamicLabel={getDynamicLabelBlockList('types', types)}
                open={isActiveMenu(1)}
                toggleOpen={(bool) => toggleActiveMenu(1, bool)}
                nextLabel="Volgende: Waar"
                nextOnClick={() => toggleActiveMenu(2)}
                handleSearch={() => handleSearch(filter)}
                filterMenuStyle={{width: 374}}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('types', 1)}
              >
                <BlockList
                  selectChildren={true}
                  data={types}
                  state={filter.types}
                  setStateCallback={types => setFilterByPath('types', types)}
                  selectParents={false}
                  handleClickSelectAll={() => handleSelectAll('types', types)}
                  handleClickDeselectAll={() => handleResetByPath('types')}
                />
              </SelectFilter>

              {!showMap &&
                <SelectFilter
                  menuId={2}
                  label="Waar"
                  dynamicLabel={getDynamicLabelLocation('where')}
                  open={isActiveMenu(2)}
                  toggleOpen={(bool) => toggleActiveMenu(2, bool)}
                  nextLabel="Volgende: Wanneer"
                  nextOnClick={() => toggleActiveMenu(3)}
                  handleSearch={() => handleSearch(filter)}
                  isChanged={isChanged}
                  handleReset={() => handleResetByPath('where', 2)}
                >
                  <LocationForm
                    state={filter.where}
                    setStateCity={city => setFilterByPath('where.city', city)}
                    setStatePostalCode={postal_code => setFilterByPath('where.postal_code', postal_code)}
                    setStateRadius={radius => setFilterByPath('where.radius', radius)}
                    showRadius={true}
                  />
                </SelectFilter>
              }

              <SelectFilter
                menuId={3}
                label="Wanneer"
                dynamicLabel={getDynamicLabelWhen('when')}
                open={isActiveMenu(3)}
                toggleOpen={(bool) => toggleActiveMenu(3, bool)}
                nextLabel="Volgende: Branche"
                nextOnClick={() => toggleActiveMenu(4)}
                handleSearch={() => handleSearch(filter)}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('when', 3)}
              >
                <Calendar
                  range
                  value={filter.when}
                  onChange={(when) => setFilterByPath('when', when)}
                  weekStartDayIndex={1}
                  shadow={false}
                  weekDays={weekDays}
                  months={months}
                  numberOfMonths={nCalendars}
                  minDate={new Date()}
                />
              </SelectFilter>
              <SelectFilter
                menuId={4}
                label="Branche"
                fieldLabel="Alle branches"
                dynamicLabel={getDynamicLabelBlockList('industries', industries)}
                open={isActiveMenu(4)}
                toggleOpen={(bool) => toggleActiveMenu(4, bool)}
                handleSearch={(isMobile)?null:() => handleSearch(filter)}
                filterMenuStyle={{width: 374}}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('industries', 4)}
              >
                <BlockList
                  selectChildren={true}
                  data={industries}
                  scrolling={true}
                  state={filter.industries}
                  setStateCallback={industries => setFilterByPath('industries', industries)}
                  selectParents={false}
                  handleClickSelectAll={() => handleSelectAll('industries', industries)}
                  handleClickDeselectAll={() => handleResetByPath('industries')}
                />
              </SelectFilter>
              <Button
                label="Zoeken"
                disabled={!isChanged || isLoading}
                onClick={() => handleSearch(filter)}
              />
            </Tab>
            <Tab
              label="Ik zoek een stand, truck of act"
              id="stand"
            >
              <SelectFilter
                menuId={6}
                label="Type stand of truck"
                fieldLabel="Alle type stands & trucks"
                dynamicLabel={getDynamicLabelBlockList('types', types)}
                open={isActiveMenu(6)}
                toggleOpen={(bool) => toggleActiveMenu(6, bool)}
                nextLabel="Volgende: Waar"
                nextOnClick={() => toggleActiveMenu(7)}
                handleSearch={() => handleSearch(filter)}
                filterMenuStyle={{width: 374}}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('types', 6)}
              >
                <BlockList
                  selectChildren={true}
                  data={types}
                  state={filter.types}
                  setStateCallback={types => setFilterByPath('types', types)}
                  selectParents={false}
                  handleClickSelectAll={() => handleSelectAll('types', types)}
                  handleClickDeselectAll={() => handleResetByPath('types')}
                />
              </SelectFilter>
              {!showMap &&
                <SelectFilter
                  menuId={7}
                  label="Waar"
                  dynamicLabel={getDynamicLabelLocation('where')}
                  open={isActiveMenu(7)}
                  toggleOpen={(bool) => toggleActiveMenu(7, bool)}
                  nextLabel="Volgende: Wanneer"
                  nextOnClick={() => toggleActiveMenu(8)}
                  handleSearch={() => handleSearch(filter)}
                  isChanged={isChanged}
                  handleReset={() => handleResetByPath('where', 7)}
                >
                  <LocationForm
                    state={filter.where}
                    setStateCity={city => setFilterByPath('where.city', city)}
                    setStatePostalCode={postal_code => setFilterByPath('where.postal_code', postal_code)}
                    setStateRadius={radius => setFilterByPath('where.radius', radius)}
                    showRadius={true}
                  />
                </SelectFilter>
              }
              <SelectFilter
                menuId={8}
                label="Wanneer"
                dynamicLabel={getDynamicLabelWhen('when')}
                open={isActiveMenu(8)}
                toggleOpen={(bool) => toggleActiveMenu(8, bool)}
                nextLabel="Volgende: Branche"
                nextOnClick={() => toggleActiveMenu(9)}
                handleSearch={() => handleSearch(filter)}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('when', 8)}
              >
                <Calendar
                  range
                  value={filter.when}
                  onChange={(when) => setFilterByPath('when', when)}
                  weekStartDayIndex={1}
                  shadow={false}
                  weekDays={weekDays}
                  months={months}
                  numberOfMonths={nCalendars}
                  minDate={new Date()}
                />
              </SelectFilter>
              <SelectFilter
                menuId={9}
                label="Branche"
                fieldLabel="Alle branches"
                dynamicLabel={getDynamicLabelBlockList('industries', industries)}
                open={isActiveMenu(9)}
                toggleOpen={(bool) => toggleActiveMenu(9, bool)}
                handleSearch={(isMobile)?null:() => handleSearch(filter)}
                filterMenuStyle={{width: 374}}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('industries', 9)}
              >
                <BlockList
                  selectChildren={true}
                  data={industries}
                  scrolling={true}
                  state={filter.industries}
                  setStateCallback={industries => setFilterByPath('industries', industries)}
                  selectParents={false}
                  handleClickSelectAll={() => handleSelectAll('industries', industries)}
                  handleClickDeselectAll={() => handleResetByPath('industries')}
                />
              </SelectFilter>
              <Button
                label="Zoeken"
                disabled={!isChanged || isLoading}
                onClick={() => handleSearch(filter)}
              />
            </Tab>
          </Tabs>
        </Tab>
        <Tab
          label="Aanbieden"
          id="offer"
        >
          <Tabs
            activeTab={filter.offer_model}
            setActiveTab={(id) => !isLoading && setFilterByPath('offer_model', id, true)}
            design="buttons"
            noPadding={true}
            isMobile={isMobile}
          >
            <Tab
              label="Ik bied een standplaats aan"
              id="spot_group"
            >
              <SelectFilter
                menuId={10}
                label="Type standplaats"
                fieldLabel="Alle type standplaatsen"
                dynamicLabel={getDynamicLabelBlockList('types', types)}
                open={isActiveMenu(10)}
                toggleOpen={(bool) => toggleActiveMenu(10, bool)}
                nextLabel="Volgende: Branche"
                nextOnClick={() => toggleActiveMenu(11)}
                handleSearch={() => handleSearch(filter)}
                filterMenuStyle={{width: 374}}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('types', 10)}
              >
                <BlockList
                  selectChildren={true}
                  data={types}
                  state={filter.types}
                  setStateCallback={types => setFilterByPath('types', types)}
                  selectParents={false}
                  handleClickSelectAll={() => handleSelectAll('types', types)}
                  handleClickDeselectAll={() => handleResetByPath('types')}
                />
              </SelectFilter>
              <SelectFilter
                menuId={11}
                label="Branche"
                fieldLabel="Alle branches"
                dynamicLabel={getDynamicLabelBlockList('industries', industries)}
                open={isActiveMenu(11)}
                toggleOpen={(bool) => toggleActiveMenu(11, bool)}
                nextLabel="Volgende: Waar"
                nextOnClick={() => toggleActiveMenu(12)}
                handleSearch={(isMobile)?null:() => handleSearch(filter)}
                filterMenuStyle={{width: 374}}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('industries', 11)}
              >
                <BlockList
                  selectChildren={true}
                  data={industries}
                  scrolling={true}
                  state={filter.industries}
                  setStateCallback={industries => setFilterByPath('industries', industries)}
                  selectParents={false}
                  handleClickSelectAll={() => handleSelectAll('industries', industries)}
                  handleClickDeselectAll={() => handleResetByPath('industries')}
                />
              </SelectFilter>
              {!showMap &&
                <SelectFilter
                  menuId={12}
                  label="Waar"
                  dynamicLabel={getDynamicLabelLocation('where')}
                  open={isActiveMenu(12)}
                  toggleOpen={(bool) => toggleActiveMenu(12, bool)}
                  handleRegister={() => handleRegister(filter)}
                  handleReset={() => handleResetByPath('where', 12)}
                >
                  <LocationForm
                    state={filter.where}
                    setStateCity={city => setFilterByPath('where.city', city)}
                    setStatePostalCode={postal_code => setFilterByPath('where.postal_code', postal_code)}
                    setStateRadius={radius => setFilterByPath('where.radius', radius)}
                    showRadius={true}
                  />
                </SelectFilter>
              }
              <Button
                label="Aanmelden"
                onClick={() => handleRegister(filter)}
              />
            </Tab>
            <Tab
              label="Ik bied een stand of truck aan"
              id="stand"
            >
              <SelectFilter
                menuId={13}
                label="Type stand of truck"
                fieldLabel="Alle type stands & trucks"
                dynamicLabel={getDynamicLabelBlockList('types', types)}
                open={isActiveMenu(13)}
                toggleOpen={(bool) => toggleActiveMenu(13, bool)}
                nextLabel="Volgende: Branche"
                nextOnClick={() => toggleActiveMenu(14)}
                handleSearch={() => handleSearch(filter)}
                filterMenuStyle={{width: 374}}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('types', 13)}
              >
                <BlockList
                  selectChildren={true}
                  data={types}
                  state={filter.types}
                  setStateCallback={types => setFilterByPath('types', types)}
                  selectParents={false}
                  handleClickSelectAll={() => handleSelectAll('types', types)}
                  handleClickDeselectAll={() => handleResetByPath('types')}
                />
              </SelectFilter>
              <SelectFilter
                menuId={14}
                label="Branche"
                fieldLabel="Alle branches"
                dynamicLabel={getDynamicLabelBlockList('industries', industries)}
                open={isActiveMenu(14)}
                toggleOpen={(bool) => toggleActiveMenu(14, bool)}
                nextLabel="Volgende: Waar"
                nextOnClick={() => toggleActiveMenu(15)}
                handleSearch={(isMobile)?null:() => handleSearch(filter)}
                filterMenuStyle={{width: 374}}
                isChanged={isChanged}
                handleReset={() => handleResetByPath('industries', 14)}
              >
                <BlockList
                  selectChildren={true}
                  data={industries}
                  scrolling={true}
                  state={filter.industries}
                  setStateCallback={industries => setFilterByPath('industries', industries)}
                  selectParents={false}
                  handleClickSelectAll={() => handleSelectAll('industries', industries)}
                  handleClickDeselectAll={() => handleResetByPath('industries')}
                />
              </SelectFilter>
              {!showMap &&
                <SelectFilter
                  menuId={15}
                  label="Waar"
                  dynamicLabel={getDynamicLabelLocation('where')}
                  open={isActiveMenu(15)}
                  toggleOpen={(bool) => toggleActiveMenu(15, bool)}
                  handleRegister={() => handleRegister(filter)}
                  handleReset={() => handleResetByPath('where', 15)}
                >
                  <LocationForm
                    state={filter.where}
                    setStateCity={city => setFilterByPath('where.city', city)}
                    setStatePostalCode={postal_code => setFilterByPath('where.postal_code', postal_code)}
                    setStateRadius={radius => setFilterByPath('where.radius', radius)}
                    showRadius={true}
                  />
                </SelectFilter>
              }
              <Button
                label="Aanmelden"
                onClick={() => handleRegister(filter)}
              />
            </Tab>
          </Tabs>
        </Tab>
      </Tabs>
      <div
        className={(activeMenu) ? "search-filter-backdrop show" : "search-filter-backdrop"}
        onClick={handleBackdropClick}
      />
    </div>
  )
}

export default SearchFilter
