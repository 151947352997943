import React from "react";
import {Link, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import './style.scss'

const LiUserMenuButton = (props) => {
  const location = useLocation();
  const {
    title = '',
    count,
    to = '/',
    label = '',
    className = '',
    icon,
  } = props
  let newClassName = 'component-li-user-menu-button ' + className

  return (
    <li className={ (location.pathname === to) ? newClassName + ' active-item ' : newClassName}>
      <Link
        to={to}
        title={title}
      >
        {icon && <img src={icon} alt={title}/>}
        {label}
        {!!count && <div className="count">{count}</div>}
      </Link>
    </li>
  )
}

LiUserMenuButton.prototype = {
  className: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  label: PropTypes.string,
}

export default LiUserMenuButton
