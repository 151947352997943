import initPrice from "./initPrice";

const initSpotGroup = {
  public: 1,
  available: 1,
  label: '',
  slug: null,
  lat: null,//'52.2129919',
  lng: null,//'5.2793703',
  availabilities: [],
  business_hour: null,
  multi_spot: 1,
  requests_spot_map_creation:false,
  price: initPrice,
  options: [],
  infos: [],
}
export default initSpotGroup
