import React from 'react'
import Popup from "../Popup/Popup";
import Media from "../Media/Media";
import Price from "../Price/Price";
import usePrice from "../../hooks/usePrice";
import useIndustry from "../../hooks/useIndustry";
import PropTypes from "prop-types";
import './style.scss'

const SpotPopup = (props) => {
  const {
    spot,
    iteration,
    setShowPopupSpot,
    showPopupSpot,
  } = props

  const {
    hasDifferentDayPrices,
    getFormattedDayPriceByKey,
  } = usePrice()

  const {getIndustry} = useIndustry()

  return (
    <div className="component-spot-popup">
      <Popup
        showPopup={showPopupSpot}
        setShowPopup={setShowPopupSpot}
        iteration={iteration}
        triggerPopup={() => showPopupSpot}
        title={'Standplaats: ' + spot.label}
      >
        <div className="component-spot-popup-content">

          {spot.overview_media &&
            <Media
              media={spot.overview_media}
              size="tile"
            />
          }

          {spot.description &&
            <>
              <h3>Beschrijving</h3>
              <p>
                {spot.description && spot.description.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br/>
                  </React.Fragment>
                ))}
              </p>
            </>
          }

          <h3>Prijs</h3>
          <Price
            bookable={spot}
            showInfo={true}
          />

          {hasDifferentDayPrices(spot) && spot.price.type !== 'negotiate' &&
            <div>
              <div>Maandag: <strong>{getFormattedDayPriceByKey(spot, 'mo')}</strong></div>
              <div>Dinsdag: <strong>{getFormattedDayPriceByKey(spot, 'tu')}</strong></div>
              <div>Woensdag: <strong>{getFormattedDayPriceByKey(spot, 'we')}</strong></div>
              <div>Donderdag: <strong>{getFormattedDayPriceByKey(spot, 'th')}</strong></div>
              <div>Vrijdag: <strong>{getFormattedDayPriceByKey(spot, 'fr')}</strong></div>
              <div>Zaterdag: <strong>{getFormattedDayPriceByKey(spot, 'sa')}</strong></div>
              <div>Zondag: <strong>{getFormattedDayPriceByKey(spot, 'su')}</strong></div>
            </div>
          }

          {'industries_ids' in spot && spot.industries_ids && <>
            {!!spot.industries_ids.length ?
              <>
                <h3>Branches</h3>
                <div className="industries">
                  <span className="center">Aantal branches: {spot.industries_ids.length}:&nbsp;&nbsp;</span>
                  {spot.industries_ids.map((industry_id, i) => {
                    const industry = getIndustry(industry_id)
                    return (<div key={i}>{industry.label}, </div>)
                  })}
                </div>
              </>
              :
              <>
                <h3>Branches</h3>
                <p>Alle Branches toegestaan</p>
              </>
            }
          </>}

          <h3>Afmetingen</h3>
          <span>Lengte: {spot.length} meter</span> <br/>
          <span>Breedte: {spot.width} meter</span>

        </div>
      </Popup>
    </div>
  )
}

SpotPopup.prototype = {
  spot: PropTypes.object.isRequired,
  iteration: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  showPopupSpot: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  setShowPopupSpot: PropTypes.func.isRequired,
}

export default SpotPopup
