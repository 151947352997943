import React, {useState} from "react"
import {weekDays, months} from "../../data/calendar";
import {Calendar as RMDPCalendar} from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import Loading from "../Loading/Loading";
import Tabs from "../Tabs/Tabs";
import Tab from "../Tabs/Tab";
import './style.scss'

const Calendar = (props) => {

  const {
    dates,
    setDates,
    isLoadingBookings,
    loadMonthBookings,
    min,
    max,
    current,
    mapDays,
  } = props

  const [activeTab, setActiveTab] = useState('incidental')

  return (
    <div className="component-calendar">
      <div className="cal-container">
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          noPadding={true}
        >
          <Tab label="Incidenteel" id="incidental">
            <div className="tab incidental">
              {isLoadingBookings && <Loading/>}
              <div className="calendar">
                <RMDPCalendar
                  multiple
                  sort
                  value={dates}
                  onChange={setDates}
                  plugins={[<DatePanel
                    // onClick={(date) => loadMonthBookings(date)}
                  />]}
                  weekStartDayIndex={1}
                  shadow={false}
                  weekDays={weekDays}
                  months={months}
                  currentDate={current}
                  minDate={min}
                  maxDate={max}
                  disabled={isLoadingBookings}
                  onMonthChange={loadMonthBookings}
                  onYearChange={loadMonthBookings}
                  mapDays={mapDays}
                />
              </div>
            </div>
          </Tab>
          <Tab label="Doorlopend" id="continuous">
            <div className="tab continuous">
              <p>We zijn druk aan het werk aan dit nieuwe platform. Helaas is het nu nog niet mogelijk om
                doorlopende
                boekingen te doen</p>
              <p><strong>Stuur ons een bericht in de chat als je een doorlopende boeking wil doen</strong>
              </p>
              <p>Excusses voor het ongemakt</p>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

Calendar.propTypes = {
//   enableDisabledDays: PropTypes.bool,
//   showHeader: PropTypes.bool,
//   dates: PropTypes.object.isRequired,
//   setDates: PropTypes.object.isRequired,
//   bookable: PropTypes.object.isRequired,
//   booking: PropTypes.object.isRequired,
}

export default Calendar
